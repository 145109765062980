import axios from "axios";
import swal from "sweetalert2";
import TurbolinksAdapter from "vue-turbolinks";
// https://github.com/logaretm/vee-validate/issues/2234
import VeeValidate from "vee-validate";
import VModal from "vue-js-modal";
import VTooltip from "v-tooltip";
// @ts-ignore
import Multiselect from "vue-multiselect";
import ActionCableVue from "actioncable-vue";
import Paginate from "vuejs-paginate";
import Croppa from "vue-croppa";
import {VueHammer} from "vue2-hammer";
import VCalendar from 'v-calendar';

import Vue from "vue/dist/vue.esm";

import {Icon} from "leaflet";
import {LMap, LTileLayer, LMarker, LTooltip} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";

// TS Can't find the .vue modules, so ignore it
// @ts-ignore
import FlashMessage from "../components/flash_message.vue";
// @ts-ignore
import StyledCard from "../components/styled_card.vue";
// @ts-ignore
import RecordTeaser from "../components/records/teaser.vue";
// @ts-ignore
import RecordIndex from "../components/records/index.vue";
// @ts-ignore
import SheetEdit from "../components/sheets/edit.vue";
// @ts-ignore
import SheetDiff from "../components/sheets/diff.vue";
// @ts-ignore
import SheetPerform from "../components/sheets/perform.vue";
// @ts-ignore
import PerformModeNav from "../components/perform_mode_nav.vue";
// @ts-ignore
import ListEdit from "../components/lists/edit.vue";
// @ts-ignore
import ListPerform from "../components/lists/perform.vue";
// @ts-ignore
import GroupInvitationEdit from "../components/group_invitations/edit.vue";
// @ts-ignore
import GroupInvitationTeaser from "../components/group_invitations/teaser.vue";
// @ts-ignore
import GroupEdit from "../components/groups/edit.vue";
// @ts-ignore
import GroupEditMembers from "../components/groups/edit_members.vue";
// @ts-ignore
import SubscriptionInvitationEdit from "../components/subscription_invitations/edit.vue";
// @ts-ignore
import SubscriptionInvitationTeaser from "../components/subscription_invitations/teaser.vue";
// @ts-ignore
import SubscriptionEdit from "../components/subscriptions/edit.vue";
// @ts-ignore
import SubscriptionEditMembers from "../components/subscriptions/edit_members.vue";
// @ts-ignore
import UserProfileEdit from "../components/user_profiles/edit.vue";
// @ts-ignore
import NotificationTeaser from "../components/notifications/teaser.vue";
// @ts-ignore
import NotificationGroupInvitationTeaser from "../components/notifications/notification_group_invitation_teaser.vue";
// @ts-ignore
import NotificationSubscriptionInvitationTeaser from "../components/notifications/notification_subscription_invitation_teaser.vue";
// @ts-ignore
import ChatNew from "../components/chats/new.vue";
// @ts-ignore
import ChatShow from "../components/chats/show.vue";
// @ts-ignore
import HomepageDemo from "../components/homepage/homepage_demo.vue"
// @ts-ignore
import StripeTipForm from "../components/stripe/tip_form.vue"
// @ts-ignore
import StripeTipLinkButton from "../components/stripe/tip_link_button.vue"
// @ts-ignore
import StripeCard from "../components/stripe/card.vue"
// @ts-ignore
import StripePaymentIntent from "../components/stripe/payment_intent.vue"
// @ts-ignore
import StripeTipPaymentIntent from "../components/stripe/tip_payment_intent.vue"
// @ts-ignore
import EventCalendar from "../components/events/calendar.vue";
// @ts-ignore
import EventEdit from "../components/events/edit.vue";
// @ts-ignore
import LocationEdit from "../components/locations/edit.vue";
// @ts-ignore
import LocationInlineEdit from "../components/locations/inline_edit.vue";
// @ts-ignore
import LocationCard from "../components/locations/card.vue";
// @ts-ignore
import LocationMap from "../components/locations/map.vue";
// @ts-ignore
import WysiwygEditor from "../components/wysiwyg/editor.vue";

// TODO: https://github.com/rails/webpacker/blob/master/README.md#lazy-loading-integration

// Vue bootstrap
Vue.use(TurbolinksAdapter);
Vue.use(VeeValidate);
Vue.use(VModal);
Vue.use(VTooltip);
Vue.use(Croppa);
Vue.use(VCalendar);
// Vue.use(Toasted, {iconPack: "fontawesome"});
Vue.use(VueHammer);

Vue.use(ActionCableVue, {
  debug: false,
  debugLevel: "error",
  connectionUrl: `ws://${window.location.hostname}/cable`,
  connectImmediately: true
});

Vue.config.productionTip = false;

// Vue.toasted.register("bl_info", "Info!", {
//   className: "bl-info",
//   duration: 5000,
//   type: "info",
//   icon: "exclamation-circle"
// });
//
// Vue.toasted.register("bl_success", "Success!", {
//   className: "bl-success",
//   duration: 5000,
//   type: "success",
//   icon: "check-circle"
// });
//
// Vue.toasted.register("bl_warning", "Warning!", {
//   className: "bl-warning",
//   duration: 5000,
//   type: "info",
//   icon: "exclamation-circle"
// });
//
// Vue.toasted.register("error", "Error!", {
//   className: "bl-error",
//   duration: 5000,
//   type: "error",
//   icon: "times-circle"
// });

document.addEventListener("turbolinks:load", () => {
  // This code will setup headers of X-CSRF-Token that it grabs from rails generated token in meta tag.
  if (document.querySelector('meta[name="csrf-token"]')) {
    axios.defaults.headers.common["X-CSRF-Token"] = document.querySelector("meta[name='csrf-token']").getAttribute("content");
  }

  axios.defaults.headers.common["Accept"] = "application/json";

  // Used by olive_branch gem to normalize JS camelCase on front end and
  // Ruby underscore case on backend.
  axios.defaults.headers.common["Key-Inflection"] = "camel";

  Vue.component("multiselect", Multiselect);
  Vue.component("paginate", Paginate);

  Vue.component("l-map", LMap);
  Vue.component("l-tile-layer", LTileLayer);
  Vue.component("l-marker", LMarker);
  Vue.component("l-tooltip", LTooltip);

  Vue.component("flash-message", FlashMessage);
  Vue.component("styled-card", StyledCard);
  Vue.component("record-teaser", RecordTeaser);
  Vue.component("record-index", RecordIndex);
  Vue.component("sheet-edit", SheetEdit);
  Vue.component("sheet-diff", SheetDiff);
  Vue.component("sheet-perform", SheetPerform);
  Vue.component("perform-mode-nav", PerformModeNav);
  Vue.component("list-edit", ListEdit);
  Vue.component("list-perform", ListPerform);
  Vue.component("group-invitation-edit", GroupInvitationEdit);
  Vue.component("group-invitation-teaser", GroupInvitationTeaser);
  Vue.component("group-edit", GroupEdit);
  Vue.component("group-edit-members", GroupEditMembers);
  Vue.component("subscription-invitation-edit", SubscriptionInvitationEdit);
  Vue.component("subscription-invitation-teaser", SubscriptionInvitationTeaser);
  Vue.component("subscription-edit", SubscriptionEdit);
  Vue.component("subscription-edit-members", SubscriptionEditMembers);
  Vue.component("user-profile-edit", UserProfileEdit);
  Vue.component("notification-teaser", NotificationTeaser);
  Vue.component("notification-group-invitation-teaser", NotificationGroupInvitationTeaser);
  Vue.component("notification-subscription-invitation-teaser", NotificationSubscriptionInvitationTeaser);
  Vue.component("chat-new", ChatNew);
  Vue.component("chat-show", ChatShow);
  Vue.component("homepage-demo", HomepageDemo);
  Vue.component("stripe-tip-form", StripeTipForm);
  Vue.component("stripe-tip-link-button", StripeTipLinkButton);
  Vue.component("stripe-card", StripeCard);
  Vue.component("stripe-payment-intent", StripePaymentIntent);
  Vue.component("stripe-tip-payment-intent", StripeTipPaymentIntent);
  Vue.component("event-calendar", EventCalendar);
  Vue.component("event-edit", EventEdit);
  Vue.component("location-edit", LocationEdit);
  Vue.component("location-inline-edit", LocationInlineEdit);
  Vue.component("location-card", LocationCard);
  Vue.component("location-map", LocationMap);
  Vue.component("wysiwyg-editor", WysiwygEditor);

  const app = new Vue({
    el: "#app",
    created() {
      // Add a root level swal utilities so that
      // child components don't have to register redundant versions

      let toastPosition = "top-end";
      const mobileBreakPoint = 992;

      // If this is a small screen, show notifications on the
      // bottom so they don't get in the way of the menu
      if (window.innerWidth < mobileBreakPoint) {
        toastPosition = "bottom-end";
      }

      this.swalToast = swal.mixin({
        toast: true,
        // @ts-ignore
        position: toastPosition,
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', swal.stopTimer);
          toast.addEventListener('mouseleave', swal.resumeTimer);
        }
      });

      this.swalGeneric = swal.mixin({
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          cancelButton: "btn btn-secondary"
        }
      });

      this.swalDelete = swal.mixin({
        title: "Are you sure you want to delete this?",
        text: "You won't be able to undo this.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger mr-4",
        },
        confirmButtonText: "Yes, delete it."
      });

      this.swalUnsavedChangesConfirm = swal.mixin({
        title: "You have unsaved changes.",
        text: "Are you sure you want to leave the page?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger mr-4",
        },
        confirmButtonText: "Yes, leave the page."
      });

      this.swalChangeAccessGroupConfirm = swal.mixin({
        title: "Are you sure you want to change this?",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger mr-4",
        },
        confirmButtonText: "Change Access Group",
      });

      // ----------------------------------------------------
      // These are a series of helper functions to be used by
      // child components to keep the code DRY
      // ----------------------------------------------------

      this.convertBooleanToEnum = function (booleanValue: boolean, enumOptions: object) {
        // https://stackoverflow.com/a/22239859
        // @ts-ignore
        booleanValue = booleanValue | 0;

        // @ts-ignore
        return enumOptions[booleanValue];
      };

      this.formatDate = function (dateString: string): string {
        // TODO: format date server side
        let date = new Date(dateString);

        return Intl.DateTimeFormat(
          "en-US",
          {
            year: "numeric", month: "numeric", day: "numeric",
            hour: "numeric", minute: "numeric", second: "numeric",
            timeZoneName: "short"
          }
        ).format(date);
      };

      this.formatCentsAsDollars = function (cents: number): string {
        let formattedDollars = cents / 100;

        // @ts-ignore;
        formattedDollars = formattedDollars.toFixed(2);

        return `$${formattedDollars}`;
      };

      // TODO: singleton?
      this.getStripeInstance = function () {
        let stripeInstance = null;

        // @ts-ignore;
        if (typeof Stripe === 'undefined') {
          return stripeInstance;
        }

        let stripePublicKey = null;

        if (document.querySelector("meta[name='stripe-pk']")) {
          stripePublicKey = document.querySelector("meta[name='stripe-pk']").getAttribute("content");
        }

        if (!stripePublicKey) {
          return stripeInstance;
        }

        // @ts-ignore;
        stripeInstance = Stripe(stripePublicKey);

        if (!stripeInstance) {
          return null;
        }

        return stripeInstance;
      };

      // TODO: don't rely on binding 'this' scope, but rather pass
      // everything in
      this.groupSelectOptions = function (groups: Array<Object>): Array<Object> {
        let groupSelectOptions = [];
        let userGroup = null;

        _forEach(groups, (groupObject) => {
          let group = _cloneDeep(groupObject);

          if (group.isGroupForUserAccount) {
            // Set the user group aside, so it can
            // be added to the front of the array
            group.displayTitle += " (only you)";
            userGroup = group;
          } else {
            groupSelectOptions.push(group);
          }
        });

        groupSelectOptions.unshift(userGroup);

        return groupSelectOptions;
      };

      this.groupsById = function (groups: Array<Object>): Object {
        let groupsById = {};

        _forEach(groups, (groupObject) => {
          let group = _cloneDeep(groupObject);

          if (group.isGroupForUserAccount) {
            // Set the user group aside, so it can
            // be added to the front of the array
            group.displayTitle += " (only you)";
          }

          groupsById[group.id] = group;
        });

        return groupsById;
      };

      this.modelStateHasChanged = function (initialState, currentState): boolean {
        return (
          JSON.stringify(currentState) !== JSON.stringify(initialState)
        );
      };

      this.isEdit = function (model): boolean {
        let isEdit: boolean = false;

        if (model.id) {
          isEdit = true;
        }

        return isEdit;
      };

      this.saveButtonText = function (hasErrors: boolean): String {
        let saveButtonText = "Save";

        if (hasErrors) {
          saveButtonText = "Fix errors to save";
        }

        return saveButtonText;
      };

      this.apiHttpMethod = function (): string {
        let apiHttpMethod: string = "post";

        if (this.isEdit) {
          apiHttpMethod = "put";
        }

        return apiHttpMethod;
      };

      this.onSubmit = async function (event: object) {
        let isValid: boolean = await this.$validator.validateAll();

        if (!isValid) {
          return;
        }

        this.doSave();
      };

      this.doSave = async function (endpoint: string, data, redirectPath = null) {
        try {
          const response = await axios[this.apiHttpMethod](
            endpoint,
            data
          );

          if (!response.data && !response.data.id) {
            return;
          }

          this.allowedToLeave = true;

          if (redirectPath) {
            // @ts-ignore
            Turbolinks.visit(redirectPath);
          } else {
            let urlSlug = response.data.slug || response.data.id;
            // @ts-ignore
            Turbolinks.visit(`${this.endpointBase}/${urlSlug}`);
          }
        } catch (error) {
          this.$root.handleRequestError("saving", this.modelDisplayName, error);
        }
      };

      this.doCancel = async function (skipValidation = false) {
        if (!this.stateHasChanged || skipValidation) {
          window.history.back();
          return;
        }

        const shouldCancel = await this.$root.swalUnsavedChangesConfirm.fire({});

        if (shouldCancel.value) {
          window.history.back();
        }
      };

      this.doDelete = async function () {
        const shouldDelete = await this.$root.swalDelete.fire({});

        if (!shouldDelete.value) {
          return;
        }

        try {
          const response = await axios.delete(this.endpoint);

          this.allowedToLeave = true;
          // @ts-ignore
          Turbolinks.visit(`${this.endpointBase}`);
        } catch (error) {
          this.$root.handleRequestError("deleting", this.modelDisplayName, error);
        }
      };

      this.turboLinksBeforeVisitCallBack = async function (event) {
        if (!this.modelStateHasChanged || this.allowedToLeave) {
          return;
        }

        event.preventDefault();
        let navigationUrl = event.data.url;

        const shouldLeave = await this.$root.swalUnsavedChangesConfirm.fire({});

        if (shouldLeave.value) {
          this.allowedToLeave = true;
          // @ts-ignore
          Turbolinks.visit(navigationUrl);
        }
      };

      this.handleRequestError = function (operation: string, resourceName: string, error): void {
        let modalMarkup: string = `<p>There was an issue ${operation} the ${resourceName}.</p>`;

        if (
          error.response &&
          error.response.headers["content-type"].includes("application/json") &&
          error.response.data
        ) {
          modalMarkup += '<div class="text-left">';

          _forEach(error.response.data, (errorMessages, property) => {
            if (property !== "base") {
              return;
            }

            // modalMarkup += `<h4>${property}</h4>`;
            modalMarkup += `<ul class="mt-2">`;
            _forEach(errorMessages, errorMessage => {
              modalMarkup += `<li>${errorMessage}</li>`;
            });
            modalMarkup += `</ul>`;
          });

          modalMarkup += "</div>";
        }

        swal.fire({
          icon: "error",
          html: modalMarkup
        });
      };
    }
  });

  if (
    document.querySelector("meta[name='s-dsn-k']") &&
    document.querySelector("meta[name='s-dsn-p']")
  ) {
    const sDsnK = document.querySelector("meta[name='s-dsn-k']").getAttribute("content");
    const sDsnP = document.querySelector("meta[name='s-dsn-p']").getAttribute("content");

    Sentry.init({
      // @ts-ignore;
      dsn: `https://${sDsnK}@sentry.io/${sDsnP}`,
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true
        })
      ],
    });
  }
});
