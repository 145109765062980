









































































export default {
  fontSizeClassPrefix: "font-size",
  props: {
    backUrl: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    return {
      fontSizeOffsetAmount: 0,
      showNav: false
    };
  },
  computed: {
    fontSizeIncreaseClassPrefix: function (): string {
      return `${this.$options.fontSizeClassPrefix}-increase`;
    },
    fontSizeDecreaseClassPrefix: function (): string {
      return `${this.$options.fontSizeClassPrefix}-decrease`;
    },
    fontSizeClass: function (): string {
      let fontSizeCLass = "";

      if (!this.fontSizeOffsetAmount) {
        return fontSizeCLass;
      }

      if (this.fontSizeOffsetAmount === 0) {
        fontSizeCLass = "";
      } else if (this.fontSizeOffsetAmount > 0) {
        fontSizeCLass = `${this.fontSizeIncreaseClassPrefix}-${this.fontSizeOffsetAmount}`;
      } else if (this.fontSizeOffsetAmount < 0) {
        let serializedNewFontSizeAmountOffsetAmount = this.fontSizeOffsetAmount.toString();
        serializedNewFontSizeAmountOffsetAmount = serializedNewFontSizeAmountOffsetAmount.split("-").join("");

        fontSizeCLass = `${this.fontSizeDecreaseClassPrefix}-${serializedNewFontSizeAmountOffsetAmount}`;
      }

      return fontSizeCLass;
    },
    decreaseDisabled: function (): boolean {
      return this.fontSizeOffsetAmount < -4;
    },
    resetDisabled: function (): boolean {
      return this.fontSizeOffsetAmount === 0;
    },
    increaseDisabled: function (): boolean {
      return this.fontSizeOffsetAmount > 4;
    },
    fontSizeIndicator: function () {
      let fontSizeIndicator = null;

      if (this.fontSizeOffsetAmount) {
        if (this.fontSizeOffsetAmount > 0) {
          fontSizeIndicator = `+${this.fontSizeOffsetAmount}`;
        } else if (this.fontSizeOffsetAmount < 0) {
          fontSizeIndicator = `${this.fontSizeOffsetAmount}`;
        }
      }

      return fontSizeIndicator;
    }
  },
  methods: {
    doResetFontSize: function (): void {
      this.resetBodyFontSizeClasses();
      this.fontSizeOffsetAmount = 0;
    },
    doAdjustFontSize: function (offsetAmount: number): void {
      this.fontSizeOffsetAmount += offsetAmount;
      this.resetBodyFontSizeClasses();

      if (this.fontSizeClass) {
        document.body.classList.add(this.fontSizeClass);
      }
    },
    resetBodyFontSizeClasses: function (): void {
      document.body.classList.forEach((className, classIndex) => {
        if (!className.startsWith(this.$options.fontSizeClassPrefix)) {
          return;
        }

        document.body.classList.remove(className);
      });
    },
    showNavTrue: function (): void {
      this.showNav = true;
    },
    showNavFalse: function (): void {
      this.showNav = false;
    }
  },
  beforeMount: function (): void {
    let currentFontSizeClass = null;

    document.body.classList.forEach((className, classIndex) => {
      if (!className.startsWith(this.$options.fontSizeClassPrefix)) {
        return;
      }

      currentFontSizeClass = className;
    });

    let currentFontSizeAction = null;
    let currentFontSizeOffsetAmount = "0";

    if (currentFontSizeClass) {
      currentFontSizeAction = currentFontSizeClass
        .split(this.$options.fontSizeClassPrefix)
        .join("");

      const classSeparatorCharacter = "-";

      if (currentFontSizeAction.charAt(0) === classSeparatorCharacter) {
        currentFontSizeAction = currentFontSizeAction.substring(1);
      }

      if (currentFontSizeAction.includes(classSeparatorCharacter)) {
        currentFontSizeAction = currentFontSizeAction.split(classSeparatorCharacter);
        currentFontSizeOffsetAmount = currentFontSizeAction[1];
        currentFontSizeAction = currentFontSizeAction[0];
      }
    }

    if (currentFontSizeAction === "decrease") {
      currentFontSizeOffsetAmount = `-${currentFontSizeOffsetAmount}`;
    }

    // @ts-ignore
    currentFontSizeOffsetAmount = parseInt(currentFontSizeOffsetAmount);

    this.fontSizeOffsetAmount = currentFontSizeOffsetAmount;
  }
}
