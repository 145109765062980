












































import _map from "lodash/map";

import {latLng, icon} from "leaflet";
import {LMap, LTileLayer, LMarker, LPopup, LTooltip} from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip
  },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  data() {
    let center = latLng(this.items[0].latitude, this.items[0].longitude);
    let zoom = 13;

    return {
      showMap: true,
      center: center,
      currentCenter: center,
      currentZoom: zoom,
      icon: icon({
        iconUrl: "/images/map-marker.png",
        iconAnchor: [12, 13]
      }),
      mapAttribution: "&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors",
      mapOptions: {
        zoomSnap: 0.5
      },
      tileUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: zoom,
    }
  },
  computed: {
    itemsInLeafletFormat: function () {
      return _map(this.items, (item) => {
        item.latLng = latLng(item.latitude, item.longitude);

        return item;
      });
    }
  },
  methods: {
    centerUpdate(center) {
      this.currentCenter = center;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
  }
}
