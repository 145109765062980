














































































export default {
  props: {
    record: {
      type: Object,
      required: true
    },
    iconClasses: {
      type: String,
      default: ""
    }
  },
  // data: function (): object {
  //   return {};
  // },
  computed: {
    wrapperClass: function (): string {
      let wrapperClass = "mb-5";

      if (!this.record.canShow) {
        wrapperClass += " opacity-65";
      }

      return wrapperClass;
    }
  },
  // methods: {}
}
