
























































































export default {
  billingNameRequiredMessage: "Billing name is required",
  props: {
    amount: {
      required: true,
      type: Number
    },
    autoSubmit: {
      type: Boolean,
      default: false
    },
    cancelCallback: Function,
    clientSecret: {
      type: String,
      default: ""
    },
    doCreatePaymentMethod: {
      type: Boolean,
      default: false
    },
    doConfirmCardPayment: {
      type: Boolean,
      default: false
    },
    doConfirmCardSetup: {
      type: Boolean,
      default: false
    },
    onErrorCallback: Function,
    onSuccessCallback: Function,
    saveButtonText: {
      type: String,
      default: "Set Payment Method"
    },
    stripeSavePaymentMethod: {
      type: Boolean,
      default: false
    },
  },
  data: function (): object {
    return {
      applePayLoading: true,
      hidePrButton: false,
      stripe: null,
      elements: null,
      prButton: null,
      paymentRequest: null,
      card: null,
      cardBillingName: "",
      honeyPot: "",
      initialized: false,
      errorMessage: "",
      cardComplete: false,
      finalized: false
    }
  },
  computed: {
    billingNameInvalid: function () {
      return this.errorMessage === this.$options.billingNameRequiredMessage;
    },
    dataIsValid: function () {
      return this.cardComplete && this.cardBillingName && !this.errorMessage
    },
    billingDetails: function () {
      return {
        name: this.cardBillingName
      }
    },
    paymentMethodData: function () {
      let paymentMethodData = {};

      if (this.card && this.cardBillingName) {
        paymentMethodData = {
          card: this.card,
          billing_details: this.billingDetails
        };
      }

      return paymentMethodData;
    }
  },
  methods: {
    cardChangeEventListener: function (event) {
      this.finalized = false;
      this.cardComplete = event.complete;

      if (event.error) {
        this.errorMessage = event.error.message;

        if (this.onErrorCallback) {
          this.onErrorCallback(event.error);
        }

        return;
      }

      this.checkValidation();
    },
    checkValidation: function () {
      if (!this.cardBillingName) {
        this.errorMessage = this.$options.billingNameRequiredMessage;

        return;
      }

      this.errorMessage = "";

      if (this.cardComplete && this.autoSubmit) {
        this.createPaymentMethod();
      }
    },
    createPaymentMethod: async function () {
      try {
        const data = await this.stripe.createPaymentMethod(
          {
            type: "card",
            card: this.card,
            billing_details: this.billingDetails
          }
        );

        if (data.error) {
          this.$root.handleRequestError("saving", "payment", {});
          return;
        } else {
          this.finalized = true;
          if (this.onSuccessCallback) {
            this.onSuccessCallback(data);
          }
        }
      } catch (error) {
        this.$root.handleRequestError("saving", "payment", error);
      }
    },
    confirmCardPayment: async function () {
      try {
        let confirmCardPaymentData = {
          payment_method: this.paymentMethodData,
        }

        if (this.stripeSavePaymentMethod) {
          confirmCardPaymentData["setup_future_usage"] = "off_session";
          confirmCardPaymentData["save_payment_method"] = true;
        }

        const data = await this.stripe.confirmCardPayment(this.clientSecret, confirmCardPaymentData);

        if (data.error) {
          this.$root.handleRequestError("saving", "payment", {});
          return;
        } else {
          this.finalized = true;
          if (this.onSuccessCallback) {
            this.onSuccessCallback(data);
          }
        }
      } catch (error) {
        this.$root.handleRequestError("saving", "payment", error);
      }
    },
    confirmCardSetup: async function () {
      try {
        const data = await this.stripe.confirmCardSetup(
          this.clientSecret,
          {
            payment_method: this.paymentMethodData
          }
        );

        if (data.error) {
          this.$root.handleRequestError("saving", "payment", {});
          return;
        } else {
          this.finalized = true;
          if (this.onSuccessCallback) {
            this.onSuccessCallback(data);
          }
        }
      } catch (error) {
        this.$root.handleRequestError("saving", "payment", error);
      }
    },
    onSubmit: async function () {
      if (this.honeyPot) {
        this.$root.handleRequestError("saving", "payment", {});
        return;
      }

      if (this.doCreatePaymentMethod) {
        await this.createPaymentMethod();
      } else if (this.doConfirmCardPayment) {
        await this.confirmCardPayment();
      } else if (this.doConfirmCardSetup) {
        await this.confirmCardSetup();
      }
    }
  },
  mounted: function () {
    this.stripe = this.$root.getStripeInstance();

    if (!this.stripe) {
      return;
    }

    this.elements = this.stripe.elements();

    if (!this.elements) {
      return;
    }

    this.card = this.elements.create("card", {
      style: {
        base: {
          fontFamily: "monospace",
          fontSize: "18px"
        }
      }
    });

    if (!this.card) {
      return;
    }

    this.card.mount(this.$refs.card);
    this.card.addEventListener("change", (event) => {
      this.cardChangeEventListener(event);
    });

    this.paymentRequest = this.stripe.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: this.saveButtonText,
        amount: this.amount,
      },
      // requestPayerName: true,
      // requestPayerEmail: true,
    });


    this.prButton = this.elements.create('paymentRequestButton', {
      paymentRequest: this.paymentRequest,
    });

    this.paymentRequest.canMakePayment().then((result) => {
      if (result) {
        this.prButton.mount(this.$refs.prbutton);
      } else {
        this.hidePrButton = true;
      }

      this.applePayLoading = false;
    });

    this.paymentRequest.on("paymentmethod", (ev) => {
      this.stripe.confirmCardPayment(
        this.clientSecret,
        {payment_method: ev.paymentMethod.id},
        {handleActions: false}
      ).then((confirmResult) => {
        if (confirmResult.error) {
          ev.complete('fail');
        } else {
          ev.complete('success');
          if (confirmResult.paymentIntent.status === "requires_action") {
            this.stripe.confirmCardPayment(this.clientSecret).then((result) => {
              if (result.error) {
                this.$root.handleRequestError("saving", "payment", result.error);
              } else {
                this.onSuccessCallback(result);
              }
            });
          } else {
            this.onSuccessCallback(confirmResult);
          }
        }
      });
    });

    this.initialized = true;
  }
}
