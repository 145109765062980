import Rails from "@rails/ujs";
import swal from "sweetalert2";

// use function scope to prevent polluting global
(function () {
  const confirmed = (element, result): void => {
    if (result.value) {
      // User clicked confirm button
      element.removeAttribute("data-confirm-swal");
      element.click();
    }
  };

  // Display the confirmation dialog
  const showConfirmationDialog = (element): void => {
    const message: string = element.getAttribute("data-confirm-swal");
    const text: string = element.getAttribute("data-text");
    const isDelete: string = element.getAttribute("data-is-delete") || false;

    if (isDelete) {
      swal.fire({
        title: message || "Are you sure you want to delete this?",
        text: text || "You won't be able to undo this.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-danger mr-4",
        },
        confirmButtonText: "Yes, delete it."
      }).then(result => confirmed(element, result));
    } else {
      swal.fire({
        title: message || "Are you sure?",
        text: text || "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then(result => confirmed(element, result));
    }
  };

  const allowAction = (element): boolean => {
    if (element.getAttribute("data-confirm-swal") === null) {
      return true;
    }

    showConfirmationDialog(element);
    return false;
  };

  function handleConfirm(element): void {
    if (!allowAction(this)) {
      Rails.stopEverything(element);
    }
  }

  function handleBasicSwal(element): void {
    const message: string = element.target.getAttribute("data-basic-swal");

    swal.fire({
      text: message
    });
  }

  Rails.delegate(document, "[data-confirm-swal]", "click", handleConfirm);
  Rails.delegate(document, "[data-basic-swal]", "click", handleBasicSwal);
})();
