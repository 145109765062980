














































export default {
  props: {
    doCancel: {
      type: Function,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    saveButtonText: {
      type: String,
      required: true
    },
    disableSticky: {
      type: Boolean,
      default: false
    }
  }
}
