document.addEventListener("DOMContentLoaded", function (event) {
  document.addEventListener("click", function (event) {
    const mobileNavButtonSelector = ".mobile-nav-toggle";

    // @ts-ignore
    if (!event.target.matches(mobileNavButtonSelector)) {
      return;
    }

    let navSelector = ".mobile-nav-wrapper";

    if (document.body.classList.contains('user-signed-in')) {
      navSelector = ".side-nav-mobile";
      document.body.classList.toggle("mobile-nav-open");
    }

    document.querySelector(navSelector).classList.toggle("hidden");
  }, false);
});
