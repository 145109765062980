import {Node} from "tiptap";
import {replaceText} from "tiptap-commands";

export default class Note extends Node {

  get name() {
    return "note";
  }

  get markupClassName() {
    return "note";
  }

  get defaultOptions() {
    return {
      // matcher: {
      //   char: "#",
      //   allowSpaces: false,
      //   startOfLine: false,
      // },
    };
  }

  get schema() {
    return {
      attrs: {
        noteId: {
          default: "",
        },
        noteName: {
          default: "",
        },
        noteModifier: {
          default: "",
        },
        isChord: {
          default: "",
        }
      },
      group: "inline",
      inline: true,
      // selectable: true,
      // atom: true,
      parseDOM: [{
        tag: "span[data-note-note-id]",
        getAttrs: dom => {
          const noteId = parseInt(dom.getAttribute("data-note-note-id"));
          const noteName = dom.getAttribute("data-note-note-name");
          const noteModifier = dom.getAttribute("data-note-note-modifier");
          const isChord = dom.getAttribute("data-note-is-chord");

          return {
            noteId,
            noteName,
            noteModifier,
            isChord
          };
        },
      }],
      toDOM: node => {
        let markup = node.attrs.noteName;

        if (node.attrs.isChord) {
          markup = `[${node.attrs.noteName}${node.attrs.noteModifier}]`;
        }

        return [
          "span",
          {
            "class": this.markupClassName,
            "data-note-note-id": parseInt(node.attrs.noteId),
            "data-note-note-name": node.attrs.noteName,
            "data-note-note-modifier": node.attrs.noteModifier,
            "data-note-is-chord": node.attrs.isChord
          },
          markup,
        ];
      }
    };
  }

  commands({schema}) {
    return attrs => {
      return replaceText(null, schema.nodes[this.name], attrs);
    };
  }
}
