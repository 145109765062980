// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import {Notyf} from "notyf";
import "notyf/notyf.min.css";
import "../stylesheets/application";
// import "../typescript/transition_animations";
import "../typescript/mobile_nav";
import "../typescript/help_toc";
import "../typescript/turbolinks_forms";
import "../typescript/swal_dialogs";
import "../typescript/vue_init";
// import ahoy from "ahoy.js";

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// ahoy.trackAll();

document.addEventListener("turbolinks:load", function () {
  // TODO: don't use window scope
  window.notyf = new Notyf({
    duration: 5000,
    position: {
      x: "right",
      y: "top",
    }
  });
});
