function calculateNewTransposeAmount(currentTransposeAmount: number, transposeAmount: number): number {
  let newTransposeAmount = currentTransposeAmount + transposeAmount;

  if (newTransposeAmount >= 12) {
    newTransposeAmount -= 12;
  } else if (newTransposeAmount <= -12) {
    newTransposeAmount += 12;
  }

  return newTransposeAmount;
}

function calculateTransposeIndicator(transposeAmount: number): string {
  let transposeIndicator = `${transposeAmount}`;

  if (transposeAmount > 0) {
    transposeIndicator = `+${transposeAmount}`;
  }

  return transposeIndicator;
}

function correctTransposeScaleOverflow(transposedNoteId: number, scaleLength: number): number {
  if (transposedNoteId < 0) {
    transposedNoteId = scaleLength + transposedNoteId;
  } else if (transposedNoteId > (scaleLength - 1)) {
    transposedNoteId = transposedNoteId - scaleLength;
  }

  return transposedNoteId;
}

function getNoteDisplayName(note, useFlatNotes: boolean): string {
  let displayName = note.name;

  if (note.isSharpOrFlat) {
    if (useFlatNotes) {
      displayName = note.flatName;
    } else {
      displayName = note.sharpName;
    }
  }

  return displayName;
}

function getNoteDisplayNameFromNoteIndex(noteIndex: number, scale, useFlatNotes: boolean): string {
  let note = scale[noteIndex];

  return getNoteDisplayName(note, useFlatNotes);
}

// TODO: implement transpose notes function.
// transposeNotes: function () {
//   let editorContentJSON = this.editor.getJSON();
//
//   const traverseEditorContent = (node) => {
//     for (let key in node) {
//       if (node[key] && typeof node[key] === "object") {
//         traverseEditorContent(node[key])
//       } else if (node.type === "note") {
//         let nodeId = parseInt(node.attrs.noteId);
//         let transposedNoteId = SheetUtils.correctTransposeScaleOverflow(nodeId + transposeAmount, this.scale.length);
//         let transposedNoteName = SheetUtils.getNoteDisplayNameFromNoteIndex(transposedNoteId, this.scale, this.useFlatNotes);
//
//         node.attrs.noteId = transposedNoteId;
//         node.attrs.noteName = transposedNoteName;
//       }
//     }
//   };
//
//   let transposeAmount = this.sheet.keyNote - this.intermediateKeyNote;
//
//   traverseEditorContent(editorContentJSON);
//
//   this.updateEditorContent(editorContentJSON);
// },

export default {
  calculateNewTransposeAmount,
  calculateTransposeIndicator,
  correctTransposeScaleOverflow,
  getNoteDisplayName,
  getNoteDisplayNameFromNoteIndex
}
