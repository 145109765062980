import { render, staticRenderFns } from "./notification_subscription_invitation_teaser.vue?vue&type=template&id=0d8ee002&scoped=true&"
import script from "./notification_subscription_invitation_teaser.vue?vue&type=script&lang=ts&"
export * from "./notification_subscription_invitation_teaser.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d8ee002",
  null
  
)

export default component.exports