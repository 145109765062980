




















































































import axios from "axios";

import _cloneDeep from "lodash/cloneDeep";
import _invert from "lodash/invert";

export default {
  props: {
    initialNotificationState: {
      type: Object,
      required: true
    },
    statusOptions: {
      type: Object,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    initialGroupInvitationState: {
      type: Object,
      required: true
    },
    groupInvitationEndpoint: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    let notification = _cloneDeep(this.initialNotificationState) || {
      status: "unread"
    };

    let notificationIsRead = notification.status === "read";

    let groupInvitation = _cloneDeep(this.initialGroupInvitationState) || {
      status: "sent"
    };

    return {
      notification: notification,
      notificationIsRead: notificationIsRead,
      snapshotNotificationState: {},
      groupInvitation: groupInvitation,
      snapshotGroupInvitationState: {},
      groupInvitationFinalized: false,
    };
  },
  computed: {
    statusOptionsByValue: function (): object {
      return _invert(this.statusOptions);
    },
    checkboxId: function (): string {
      //  html checkboxes are weird, and since this component is reused on the
      //  the index page, the ID needs to be scoped so that the individual checkboxes
      //  from each component don't mess with each other (treated as the same)
      return `notification-${this.notification.id}-edit__status`;
    },
    radioAcceptId: function (): string {
      //  html radios are weird, and since this component is reused on the
      //  the index page, the ID needs to be scoped so that the individual radios
      //  from each component don't mess with each other (treated as the same)
      return `group-invitation-${this.groupInvitation.id}-edit__status__accept`;
    },
    radioDeclineId: function (): string {
      return `group-invitation-${this.groupInvitation.id}-edit__status__decline`;
    },
    updatedDate: function (): string {
      return this.$parent.formatDate(this.notification.updatedAt);
    },
    notificationStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotNotificationState, this.notification);
    },
    groupInvitationStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotGroupInvitationState, this.groupInvitation);
    },
    modelStateHasChanged: function (): boolean {
      return (
        this.notificationStateHasChanged ||
        this.groupInvitationStateHasChanged
      );
    },
    apiHttpMethod: function (): string {
      return "put";
    },
  },
  // TODO: Move from watcher to computed property (and just use computed property in save action)
  watch: {
    "notificationIsRead": function (newValue, oldValue) {
      this.notification.status = this.$root.convertBooleanToEnum(newValue, this.statusOptionsByValue);
    }
  },
  methods: {
    doSave: async function () {
      // TODO: call a single endpoint
      if (this.groupInvitationStateHasChanged) {
        let groupInvitationData = _cloneDeep(this.groupInvitation);

        if ((typeof groupInvitationData.status !== undefined) && (groupInvitationData.status === null)) {
          delete groupInvitationData.status;
        }

        try {
          const response = await axios[this.apiHttpMethod](
            this.groupInvitationEndpoint,
            groupInvitationData
          );

          if (!response.data && !response.data.id) {
            return;
          }
        } catch (error) {
          this.$root.handleRequestError("saving", this.modelDisplayName, error);
        }
      }

      if (this.notificationStateHasChanged) {
        let notificationData = _cloneDeep(this.notification);

        try {
          const response = await axios[this.apiHttpMethod](
            this.endpoint,
            notificationData
          );

          if (!response.data && !response.data.id) {
            return;
          }
        } catch (error) {
          this.$root.handleRequestError("saving", this.modelDisplayName, error);
        }
      }

      this.allowedToLeave = true;
      // @ts-ignore
      Turbolinks.visit(this.endpointBase);
    },
    doDelete: async function () {
      await this.$root.doDelete.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function (): void {
    this.snapshotNotificationState = _cloneDeep(this.notification);
    this.snapshotGroupInvitationState = _cloneDeep(this.groupInvitation);
    this.groupInvitationFinalized = this.snapshotGroupInvitationState.status === "accepted";
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy(): void {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
