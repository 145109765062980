



export default {
  props: {},
  channels: {
    FlashMessageChannel: {
      connected() {
      },
      rejected() {
      },
      received(flashMessage) {
        /***************************
         NOTE:
         moved from a vue component to native JS action cable channel, since
         the vue component would disconnect / reconnect during page transitions
         (thus dropping messages)
         */
        this.$toasted.global[flashMessage.type](flashMessage.body);
      },
      disconnected() {
      }
    }
  },
  computed: {},
  methods: {},
  mounted() {
    // @ts-ignore
    this.$cable.subscribe({
      // @ts-ignore
      channel: "FlashMessageChannel"
    });
  },
}
