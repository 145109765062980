



































































































































import _cloneDeep from "lodash/cloneDeep";
import _debounce from "lodash/debounce";
import _filter from "lodash/filter";
import _forEach from "lodash/forEach";

import axios from "axios";

import {parseISO} from "date-fns";

export default {
  groupHighlightColor: {
    personal: "teal",
    group: "indigo"
  },
  props: {
    endpoint: {
      type: String,
      required: true
    },
    initialEventsState: {
      type: Array,
      required: true
    },
    showFilters: {
      type: Boolean,
      default: true
    }
  },
  data: function (): object {
    let events = _cloneDeep(this.initialEventsState);

    return {
      masks: {
        weekdays: "WWW",
      },
      calendarFilters: {
        showPersonalEvents: true,
        showGroupEvents: true,
      },
      events: events,
      componentIsMounted: false,
      isLoading: false
    };
  },
  computed: {
    filteredEvents: function () {
      return _filter(this.events, (event) => {
        let allowEvent = true;

        if (!this.calendarFilters.showPersonalEvents && event.group.isGroupForUserAccount) {
          allowEvent = false;
        }

        if (!this.calendarFilters.showGroupEvents && !event.group.isGroupForUserAccount) {
          allowEvent = false;
        }

        return allowEvent;
      });
    },
    attributes: function () {
      let items = [];

      _forEach(this.filteredEvents, (event) => {
        let date = {};

        let item = {
          highlight: {
            color: this.$options.groupHighlightColor.group,
            fillMode: "solid"
          },
          popover: {
            visibility: "hover"
          },
          customData: _cloneDeep(event),
          dates: []
        };

        if (event.group.isGroupForUserAccount) {
          item.highlight.color = this.$options.groupHighlightColor.personal;
        }

        if (event.startsAt) {
          //@ts-ignore
          date.start = parseISO(event.startsAt);

          item.customData.startsAt = this.$parent.formatDate(event.startsAt);
        }

        if (event.endsAt) {
          //@ts-ignore
          date.end = parseISO(event.endsAt);

          item.customData.endsAt = this.$parent.formatDate(event.endsAt);
        }

        item.dates.push(date);

        items.push(item);
      });

      return items;
    }
  },
  methods: {
    goToToday: async function () {
      this.$refs.mobilecalendar.move(new Date());
      this.$refs.calendar.move(new Date());
    },
    onCalendarUpdate: _debounce(async function (page) {
      if (!this.componentIsMounted) {
        return;
      }

      const day = "01";

      this.isLoading = true;
      this.events = await this.fetchEventsForDate(`${page.year}-${page.month}-${day}`);
      this.isLoading = false;
    }, 200),
    fetchEventsForDate: async function (date: string) {
      let events = [];

      try {
        const response = await axios.get(
          this.endpoint,
          {
            params: {
              starts_at: date
            }
          }
        );

        if (!response.data) {
          return events;
        }

        events = response.data;
      } catch (error) {
        this.$root.handleRequestError("updating", "calendar", error);
      }

      return events;
    }
  },
  mounted() {
    this.componentIsMounted = true;
  }
}
