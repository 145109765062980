// Make rails form tags with get & remote submit / load with turbolinks
document.addEventListener("turbolinks:load", function (event) {
  // @ts-ignore
  for (let form of document.querySelectorAll("form[method='get'][data-remote='true']")) {
    form.addEventListener("ajax:beforeSend", function (event) {
      const detail = event.detail;
      const xhr = detail[0];
      const options = detail[1];

      // @ts-ignore
      Turbolinks.visit(options.url);
      event.preventDefault();
    });
  }
});
