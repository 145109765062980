











































































import _cloneDeep from "lodash/cloneDeep";

export default {
  props: {
    actedOn: {
      type: Boolean,
      required: true
    },
    currentUserId: {
      type: Number,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    groupPath: {
      type: String,
      required: true
    },
    initialGroupInvitationState: {
      type: Object,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    // Note that title is the result of a computed property (method) on the
    // active record model, hence passing it separately from the state.
    title: {
      type: String,
      required: true
    },
    toUserPath: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    let groupInvitation = _cloneDeep(this.initialGroupInvitationState) || {
      status: "sent"
    };

    return {
      groupInvitation: groupInvitation,
      groupInvitationFinalized: false,
      snapshotGroupInvitationState: {}
    };
  },
  computed: {
    radioAcceptId: function (): string {
      //  html radios are weird, and since this component is reused on the
      //  the index page, the ID needs to be scoped so that the individual radios
      //  from each component don't mess with each other (treated as the same)
      return `group-invitation-${this.groupInvitation.id}-edit__status__accept`;
    },
    radioDeclineId: function (): string {
      return `group-invitation-${this.groupInvitation.id}-edit__status__decline`;
    },
    updatedDate: function (): string {
      return this.$parent.formatDate(this.groupInvitation.updatedAt);
    },
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotGroupInvitationState, this.groupInvitation);
    },
    apiHttpMethod: function (): string {
      return "put";
    },
    titleText: function (): string {
      // TODO: don't do this client side
      let titleText = this.title;

      let invitationSplitText = " invitation to ";
      titleText = titleText.split(invitationSplitText);
      titleText[0] = `<a href="${this.toUserPath}">${titleText[0]}</a>`;

      let toTextSplitText = " from";
      let toText = titleText[1].split(toTextSplitText);
      toText[0] = `<a href="${this.groupPath}">${toText[0]}</a>`;

      titleText[1] = toText.join(toTextSplitText);
      titleText = titleText.join(invitationSplitText);

      return titleText;
    },
    deleteButtonLabel: function (): string {
      let deleteButtonLabel = "Delete";

      if (this.actedOn) {
        deleteButtonLabel = "Can't delete once acted on"
      }

      return deleteButtonLabel;
    }
  },
  methods: {
    doSave: async function () {
      let groupInvitationData = _cloneDeep(this.groupInvitation);

      if ((typeof groupInvitationData.status !== undefined) && (groupInvitationData.status === null)) {
        delete groupInvitationData.status;
      }

      await this.$root.doSave.call(this, this.endpoint, groupInvitationData, this.endpointBase);
    },
    doDelete: async function () {
      await this.$root.doDelete.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function (): void {
    this.snapshotGroupInvitationState = _cloneDeep(this.groupInvitation);
    this.groupInvitationFinalized = this.snapshotGroupInvitationState.status === "accepted";
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy(): void {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
