




































































































































































import _cloneDeep from "lodash/cloneDeep";

export default {
  props: {
    currentUserId: {
      type: Number,
      required: true
    },
    endpoint: String,
    endpointBase: {
      type: String,
      required: true
    },
    initialSubscriptionInvitationState: Object,
    modelDisplayName: {
      type: String,
      required: true
    },
    statusOptions: Array,
    subscription: Object,
    subscriptions: Array,
    // Note that title is the result of a computed property (method) on the
    // active record model, hence passing it separately from the state.
    title: String,
    user: Object,
    users: Array
  },
  data: function (): object {
    let subscriptionId = null;
    let selectedSubscription = null;

    if (this.subscription && this.subscription.id) {
      subscriptionId = this.subscription.id;
      selectedSubscription = _cloneDeep(this.subscription);
    }

    let toUserId = null;

    if (this.user && this.user.id) {
      toUserId = this.user.id;
    }

    let subscriptionInvitation = _cloneDeep(this.initialSubscriptionInvitationState) || {
      subscriptionId: subscriptionId,
      toUserId: toUserId,
      status: null,
      fromUserId: this.currentUserId || null
    };

    return {
      subscriptionInvitation: subscriptionInvitation,
      snapshotSubscriptionInvitationState: {},
      subscriptionInvitationActedOn: false,
      allowedToLeave: false,
      selectedSubscription: selectedSubscription,
      selectedUser: null
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotSubscriptionInvitationState, this.subscriptionInvitation);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.subscriptionInvitation);
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    },
    titleText: function (): string {
      let titleText = this.title || "Invite";

      if (this.user && this.user.username) {
        titleText += ` ${this.user.username}`;
      } else {
        titleText += ` a user`;
      }

      if (this.subscription && this.subscription.title) {
        titleText += ` to join ${this.subscription.title}`;
      } else {
        titleText += ` to join a subscription`;
      }

      return titleText;
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    deleteButtonLabel: function (): string {
      let deleteButtonLabel = "Delete";

      if (this.subscriptionInvitationActedOn) {
        deleteButtonLabel = "Can't delete once acted on"
      }

      return deleteButtonLabel;
    }
  },
  methods: {
    onSubscriptionSelect: function (subscription) {
      this.subscriptionInvitation.subscriptionId = subscription.id;
    },
    onUserSelect: function (user) {
      this.subscriptionInvitation.toUserId = user.id;
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      let subscriptionInvitationData = _cloneDeep(this.subscriptionInvitation);

      if ((typeof subscriptionInvitationData.status !== undefined) && (subscriptionInvitationData.status === null)) {
        delete subscriptionInvitationData.status;
      }

      let endpoint = this.endpointBase;

      await this.$root.doSave.call(this, endpoint, subscriptionInvitationData, this.endpointBase);
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    doDelete: async function () {
      await this.$root.doDelete.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function (): void {
    this.snapshotSubscriptionInvitationState = _cloneDeep(this.subscriptionInvitation);
    this.subscriptionInvitationActedOn = !!this.snapshotSubscriptionInvitationState.status;
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy(): void {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
