























































































































































































































































































































































































































































































































































































































import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _map from "lodash/map";
import _unescape from "lodash/unescape";
import _union from "lodash/union";

import {Editor} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Strike,
  // Underline,
  // HorizontalRule,
  History
} from "tiptap-extensions";

import Note from "./body_editor/note";
import PageBreak from "./body_editor/page_break";
import BodyEditor from "./body_editor/body_editor.vue";

import StickyFooter from "../sticky_footer.vue";

import ParseNotesAndChordsFromBody from "./body_editor/body_note_parser";
import SheetUtils from "./sheet_utils";

export default {
  pasteTextModalName: "paste-text",
  defaultEditorNodeContent: "<p><br></p>",
  props: {
    customFieldTypes: {
      type: Array,
      required: true
    },
    initialSheetState: Object,
    groups: {
      type: Array,
      required: true
    },
    noteChordHelpMarkup: {
      type: String,
      required: true
    },
    scale: {
      type: Array,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    existingCustomFields: Object,
    modelDisplayName: {
      type: String,
      required: true
    },
    isDuplicate: Boolean
  },
  components: {
    BodyEditor,
    StickyFooter
  },
  data: function (): object {
    let sheet = _cloneDeep(this.initialSheetState) || {
      title: "",
      pagesData: "",
      keyNote: "",
      keyMode: "",
      groupId: null,
      useFlatNotes: true,
      customFields: [],
      deletedCustomFields: []
    };

    let customFieldLabelOptions = this.existingCustomFields.labels || [];
    let customFieldValueOptions = this.existingCustomFields.values || [];

    // Since the backend stores this as an int,
    // empty values will be passed as null, so convert
    // to empty string so we can default to the default option
    // which can't have a value of null
    if (!sheet.keyNote && sheet.keyNote !== 0) {
      sheet.keyNote = "";
    }

    return {
      activeTab: 0,
      customFieldLabelOptions: customFieldLabelOptions,
      customFieldValueOptions: customFieldValueOptions,
      noteModal: {
        command: null,
        isActive: false,
        note: {
          noteId: "",
          noteName: "",
          noteModifier: "",
          isChord: true,
        },
        validationErrors: {
          noteId: false
        }
      },
      pasteTextModalText: "",
      intermediateKeyNote: 0,
      transposeNotesOnKeyChange: true,
      sheet: sheet,
      selectedGroup: null,
      snapshotSheetState: {},
      allowedToLeave: false,
      showNoteChordHelp: false,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new Heading({levels: [1, 2, 3, 4, 5, 6]}),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new Link(),
          new Strike(),
          // new Underline(),
          // new HorizontalRule(),
          new History(),

          new Note(),
          new PageBreak()
        ],
        onUpdate: ({getJSON, getHTML}) => {
          this.updatePagesData();
        }
      })
    };
  },
  computed: {
    pages: function (): Array<string> {
      let pages = [""];

      if (this.sheet.pagesData) {
        pages = JSON.parse(this.sheet.pagesData);
      }

      return pages;
    },
    pagesMarkup: function (): string {
      return this.pages.join(PageBreak.prototype.markupTag);
    },
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotSheetState, this.sheet);
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    },
    displayScale: function (): Array<object> {
      return this.scale.map((scaleNote) => {
        let note = _cloneDeep(scaleNote);

        note.displayName = note.name;

        if (note.isSharpOrFlat) {
          if (this.sheet.useFlatNotes) {
            note.displayName = note.flatName;
          } else {
            note.displayName = note.sharpName;
          }
        }

        return note;
      });
    },
    scaleByNoteName: function () {
      let scaleByNoteName = {};

      _forEach(this.scale, function (note) {
        if (note.isSharpOrFlat) {
          scaleByNoteName[note.flatName] = note;
          scaleByNoteName[note.sharpName] = note;
        } else {
          scaleByNoteName[note.name] = note;
        }
      });

      return scaleByNoteName;
    },
    lettersInScale: function () {
      let lettersInScale = [];
      let scale = Object.getOwnPropertyNames(this.scaleByNoteName);
      let firstLetterOfScaleNotes = _map(scale, (scaleNote) => {
        return scaleNote[0];
      });

      // @ts-ignore;
      lettersInScale = new Set(firstLetterOfScaleNotes);
      lettersInScale = Array.from(lettersInScale);

      return lettersInScale;
    },
    groupSelectOptions: function (): Array<Object> {
      return this.$root.groupSelectOptions(this.groups);
    },
    groupsById: function (): Object {
      return this.$root.groupsById(this.groups);
    },
    customFieldLabelsInUse: function () {
      let customFieldLabelsInUse = [];

      _forEach(this.sheet.customFields, (customField) => {
        if (customField.fieldType !== "text") {
          return;
        }

        // @ts-ignore
        if (!customFieldLabelsInUse.includes(customField.label)) {
          customFieldLabelsInUse.push(customField.label);
        }
      });

      return _union(customFieldLabelsInUse, this.existingCustomFields.labels);
    },
    customFieldValuesInUse: function () {
      let customFieldValuesInUse = [];

      _forEach(this.sheet.customFields, (customField) => {
        if (customField.fieldType !== "text") {
          return;
        }

        // @ts-ignore
        if (!customFieldValuesInUse.includes(customField.value)) {
          customFieldValuesInUse.push(customField.value);
        }
      });

      return _union(customFieldValuesInUse, this.existingCustomFields.values);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.sheet) && !this.isDuplicate;
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    }
  },
  // TODO: replace watcher with computed
  watch: {
    "sheet.keyNote": function (newValue, oldValue) {
      this.intermediateKeyNote = oldValue;

      if (this.transposeNotesOnKeyChange) {
        this.transposeNotes();
      }
    },
    "displayScale": function (newValue, oldValue) {
      this.intermediateKeyNote = this.sheet.keyNote;

      if (this.transposeNotesOnKeyChange) {
        this.transposeNotes();
      }
    },
  },
  methods: {
    onGroupSelect: function (group) {
      this.sheet.groupId = group.id;
    },
    doInsertPasteText: function () {
      this.pasteTextModalText = _unescape(this.pasteTextModalText)
        .split("\n")
        .join(this.$options.defaultEditorNodeContent);

      this.updateEditorContent(this.pasteTextModalText);
      this.pasteTextModalText = "";
      this.$modal.hide(this.$options.pasteTextModalName);
    },
    doInsertNote: function () {
      if (!this.noteModal.note.noteId && this.noteModal.note.noteId !== 0) {
        this.noteModal.validationErrors.noteId = true;
        return;
      }

      let noteId = this.noteModal.note.noteId;
      let noteName = this.displayScale[noteId].displayName;

      let commandData = {
        noteId: noteId,
        noteName: noteName
      };

      if (this.noteModal.note.isChord) {
        // @ts-ignore
        commandData.isChord = this.noteModal.note.isChord;
        // @ts-ignore
        commandData.noteModifier = this.noteModal.note.noteModifier;
      }

      this.noteModal.command(commandData);

      this.resetNoteModalState();
    },
    detectNotesAndChordsCommandCallback: function () {
      // TODO: clean this up / break into smaller functions / extract into service
      let editorContentHTML = this.editor.getHTML();

      editorContentHTML = ParseNotesAndChordsFromBody(editorContentHTML, this.scale, this.scaleByNoteName, this.lettersInScale, this.sheet.useFlatNotes);

      this.updateEditorContent(editorContentHTML);
    },
    pasteTextCommandCallback: async function () {
      this.$modal.show(this.$options.pasteTextModalName);
    },
    noteCommandCallback: function (command) {
      this.noteModal.isActive = true;
      this.noteModal.command = command;
    },
    resetNoteModalState: function () {
      this.noteModal.isActive = false;
      this.noteModal.note = {
        noteId: "",
        noteName: "",
        noteModifier: "",
        isChord: true,
      };
      this.noteModal.validationErrors = {
        noteId: false
      };
    },
    transposeNotes: function () {
      let editorContentJSON = this.editor.getJSON();

      const traverseEditorContent = (node) => {
        for (let key in node) {
          if (node[key] && typeof node[key] === "object") {
            traverseEditorContent(node[key])
          } else if (node.type === "note") {
            let nodeId = parseInt(node.attrs.noteId);
            let transposedNoteId = SheetUtils.correctTransposeScaleOverflow(nodeId + transposeAmount, this.scale.length);
            let transposedNoteName = SheetUtils.getNoteDisplayNameFromNoteIndex(transposedNoteId, this.scale, this.sheet.useFlatNotes);

            node.attrs.noteId = transposedNoteId;
            node.attrs.noteName = transposedNoteName;
          }
        }
      };

      let transposeAmount = this.sheet.keyNote - this.intermediateKeyNote;

      traverseEditorContent(editorContentJSON);

      this.updateEditorContent(editorContentJSON);
    },
    updateEditorContent: function (content, shouldUpdatePagesData = true) {
      this.editor.clearContent();
      this.editor.setContent(content);

      if (shouldUpdatePagesData) {
        this.updatePagesData();
      }
    },
    updatePagesData: function () {
      let editorHtml = this.editor.getHTML();
      // TODO: determine why the <br> gets stripped out and has to be re-added.
      editorHtml = editorHtml.split('<p></p>').join(this.$options.defaultEditorNodeContent);

      let pages = editorHtml.split(PageBreak.prototype.markupTag);

      this.sheet.pagesData = JSON.stringify(pages);
    },
    customFieldTypeOnChange: function (customFieldId) {
      this.sheet.customFields[customFieldId].label = "";
      this.sheet.customFields[customFieldId].value = "";
    },
    customFieldLabelOnSearchChange: function (searchQuery, domElementId) {
      let customFieldId = this.getCustomFieldIdFromDomElementId(domElementId, "__label");
      let newLabel = searchQuery.trim();

      if (!newLabel) {
        return;
      }

      this.sheet.customFields[customFieldId].label = newLabel;

      this.$validator.validate();
    },
    customFieldLabelOnClose: function (value, domElementId) {
      let customFieldId = this.getCustomFieldIdFromDomElementId(domElementId, "__label");

      this.addCustomFieldLabelOption(this.sheet.customFields[customFieldId].label, domElementId);
      this.cleanUpTemporaryFieldLabelOptions();
    },
    addCustomFieldLabelOption: function (newLabel, domElementId) {
      let customFieldId = this.getCustomFieldIdFromDomElementId(domElementId, "__label");
      newLabel = newLabel.trim();

      if (!newLabel) {
        return;
      }

      this.sheet.customFields[customFieldId].label = newLabel;

      // @ts-ignore
      if (!this.customFieldLabelOptions.includes(newLabel)) {
        this.customFieldLabelOptions.push(newLabel);
      }
    },
    cleanUpTemporaryFieldLabelOptions: function () {
      this.customFieldLabelOptions = this.customFieldLabelsInUse;
    },
    customFieldValueOnSearchChange: function (searchQuery, domElementId) {
      let customFieldId = this.getCustomFieldIdFromDomElementId(domElementId, "__value");
      let newValue = searchQuery.trim();

      if (!newValue) {
        return;
      }

      this.sheet.customFields[customFieldId].value = newValue;

      this.$validator.validate();
    },
    customFieldValueOnClose: function (value, domElementId) {
      let customFieldId = this.getCustomFieldIdFromDomElementId(domElementId, "__value");

      this.addCustomFieldValueOption(this.sheet.customFields[customFieldId].value, domElementId);
      this.cleanUpTemporaryFieldValueOptions();
    },
    addCustomFieldValueOption: function (newValue, domElementId) {
      let customFieldId = this.getCustomFieldIdFromDomElementId(domElementId, "__value");
      newValue = newValue.trim();

      if (!newValue) {
        return;
      }

      this.sheet.customFields[customFieldId].value = newValue;

      // @ts-ignore
      if (!this.customFieldValueOptions.includes(newValue)) {
        this.customFieldValueOptions.push(newValue);
      }
    },
    cleanUpTemporaryFieldValueOptions: function () {
      this.customFieldValueOptions = this.customFieldValuesInUse;
    },
    addCustomField: function () {
      this.sheet.customFields.push({
        "fieldType": "text",
        "label": "",
        "value": ""
      });
    },
    removeCustomField: function (key) {
      this.sheet.customFields[key]._destroy = true;
      let customField = _cloneDeep(this.sheet.customFields[key]);

      this.sheet.customFields.splice(key, 1);

      if (customField.id) {
        this.sheet.deletedCustomFields.push(customField);
      }
    },
    undoRemoveCustomField: function (key): void {
      delete this.sheet.deletedCustomFields[key]._destroy;

      let customField = _cloneDeep(this.sheet.deletedCustomFields[key]);
      this.sheet.deletedCustomFields.splice(key, 1);
      this.sheet.customFields.push(customField);
    },
    getCustomFieldIdFromDomElementId: function (domElementId: string, propertyName: string): number {
      return parseInt(domElementId.split(propertyName)[0].split("--")[1]);
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      // TODO: computed?
      // https://stackoverflow.com/questions/11196229/posting-json-with-association-data
      let sheetData = _cloneDeep(this.sheet);

      if (sheetData.title === this.snapshotSheetState.title && this.isDuplicate) {
        sheetData.title += " duplicate";
      }

      if (
        sheetData.deletedCustomFields &&
        sheetData.deletedCustomFields.length > 0
      ) {
        sheetData.customFields = sheetData.customFields.concat(
          sheetData.deletedCustomFields
        );
      }

      sheetData.customFieldsAttributes = sheetData.customFields;
      delete sheetData.deletedCustomFields;
      delete sheetData.customFields;

      if (this.isDuplicate) {
        sheetData.id = null;
      }

      await this.$root.doSave.call(this, this.endpoint, sheetData);
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  beforeMount: function () {
    if (this.isEdit) {
      // Setting the selectedGroup fallback to a null object will break the
      // validator (thinks a group has been selected), so only use a null
      // object when editing an existing record (and therefore the group can't
      // be changed).
      this.selectedGroup = this.groupsById[this.sheet.groupId] || {
        displayTitle: ""
      };
    } else {
      this.selectedGroup = this.groupsById[this.sheet.groupId] || null;
    }

    this.sheet.deletedCustomFields = this.sheet.deletedCustomFields || [];
  },
  mounted() {
    this.editor.setContent(this.pagesMarkup);
    this.snapshotSheetState = _cloneDeep(this.sheet);
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy() {
    this.editor.destroy();
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
