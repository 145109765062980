











































































































































































import axios from "axios";

import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _forEach from "lodash/forEach";

export default {
  defaultSearchObject: {
    query: "",
    title: "",
    group: null,
    page: 1
  },
  props: {
    modelDisplayName: {
      type: String,
      required: true
    },
    records: {
      type: Array,
      required: true
    },
    totalRecords: {
      type: Number,
      required: true
    },
    groupOptions: {
      type: Object,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    iconClasses: {
      type: String,
      default: ""
    }
  },
  data: function (): object {
    let recordCount = this.totalRecords;
    let records = _cloneDeep(this.records);

    return {
      showAdvancedSearch: false,
      recordsData: records,
      recordCount: recordCount,
      search: _cloneDeep(this.$options.defaultSearchObject),
      isLoading: false,
      showTitleOrKeywordError: false
    };
  },
  computed: {
    pageCount: function (): Number {
      return Math.ceil(this.recordCount / this.perPage);
    },
    groupSelectOptions: function () {
      return this.getGroupSelectOptions();
    }
  },
  methods: {
    checkTitleAndSearch() {
      this.showTitleOrKeywordError = !!this.search.title && !!this.search.query;
    },
    getGroupSelectOptions: function () {
      let groupSelectOptions = [];

      _forEach(this.groupOptions, (groupTitle, groupId) => {
        groupSelectOptions.push({
          id: groupId,
          title: groupTitle
        });
      });

      return groupSelectOptions;
    },
    onSubmit: async function () {
      this.search.page = 1;

      if (this.showTitleOrKeywordError) {
        return;
      }

      this.doSearch();
    },
    clearField: function (fieldName: string) {
      this.search[fieldName] = this.$options.defaultSearchObject[fieldName];
      this.checkTitleAndSearch();
    },
    doClear: async function () {
      this.search = _cloneDeep(this.$options.defaultSearchObject);
      this.showTitleOrKeywordError = false;
      this.doSearch(true);
    },
    onPaginationClick: function (pageNumber: number): void {
      this.search.page = pageNumber;
      this.doSearch();
    },
    doSearch: async function (isClear = false) {
      this.isLoading = true;
      let params = this.getSearchParams();

      try {
        // TODO: get persisting from query string working. The code as it is now breaks turbolinks
        // https://github.com/turbolinks/turbolinks/issues/537
        // this.updateQueryString(params, isClear);

        const response = await axios.get(
          this.endpoint,
          {
            params: params
          }
        );

        if (!response.data) {
          return;
        }

        if (response.data.totalRecords) {
          this.recordCount = response.data.totalRecords;
        }

        if (response.data.records) {
          this.recordsData = response.data.records;
        }
      } catch (error) {
        this.$root.handleRequestError("searching", "record", error);
      }

      this.isLoading = false;
    },
    getSearchParams: function (): Object {
      let params = {};

      if (this.search.page) {
        // @ts-ignore;
        params.page = this.search.page;
      }

      if (this.search) {
        if (this.search.query) {
          // @ts-ignore;
          params.query = this.search.query;
        }

        if (this.search.title) {
          // @ts-ignore;
          params.title = this.search.title;
        }

        if (this.search.group && this.search.group.id) {
          // @ts-ignore;
          params.group = this.search.group.id;
        }
      }

      return params;
    },
    updateQueryString: function (params: Object, resetQueryString = false): void {
      if (resetQueryString) {
        window.history.replaceState(null, null, window.location.pathname);
        return;
      }

      let queryString = "?";

      _forEach(params, (paramValue, paramName) => {
        if (paramName === "page" && paramValue === 1) {
          return;
        }

        queryString += `${paramName}=${paramValue}&`;
      });

      // remove trailing &
      queryString = queryString.substring(0, queryString.length - 1);

      if (!queryString) {
        window.history.replaceState(null, null, window.location.pathname);
      } else {
        window.history.pushState("", "", queryString);
      }
    },
    setStateFromQueryString: function (): void {
      let searchParams = window.location
        .search
        .split("?")
        .join("")
        .split("&");

      if (searchParams.length > 0) {
        this.showAdvancedSearch = true;
      }

      let groupSelectOptions = this.getGroupSelectOptions();

      _forEach(searchParams, (searchParam) => {
        let searchParamArray = searchParam.split("=");

        if (searchParamArray[0] === "group") {
          this.search.group = _find(groupSelectOptions, {"id": searchParamArray[1]});
        } else if (searchParamArray[0] === "page") {
          this.search[searchParamArray[0]] = parseInt(searchParamArray[1]);
        } else if (searchParamArray[0] === "query") {
          this.search[searchParamArray[0]] = decodeURIComponent(searchParamArray[1]);
        } else {
          this.search[searchParamArray[0]] = searchParamArray[1];
        }
      });
    }
  },
  beforeMount(): void {
    // TODO: get persisting from query string working. The code as it is now breaks turbolinks
    // this.setStateFromQueryString();
  }
}
