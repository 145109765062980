


















































































































import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";

import axios from "axios";
import swal from "sweetalert2";

export default {
  props: {
    editSubscriptionMembersPath: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    incompletePaymentIntentIdErrorKey: {
      type: String,
      required: true
    },
    initialPaymentIntentNeedsReviewId: String,
    initialPaymentMethod: Object,
    initialSetupIntentId: String,
    initialSubscriptionState: Object,
    modelDisplayName: {
      type: String,
      required: true
    },
    numberOfSubscriptionMembers: {
      type: Number,
      required: true
    },
    subscriptionPaymentsBaseUrl: {
      type: String,
      required: true
    },
    subscriptionIsInStripe: Boolean,
    subscriptionPlans: {
      type: Array,
      required: true
    },
    updatePaymentMethodEndpoint: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    let subscription = _cloneDeep(this.initialSubscriptionState) || {
      title: ""
    };

    let paymentMethod = _cloneDeep(this.initialPaymentMethod) || null;

    return {
      allowedToLeave: false,
      paymentMethod: paymentMethod,
      subscription: subscription,
      paymentMethodData: null,
      editingPaymentMethod: false,
      snapshotSubscriptionState: {}
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotSubscriptionState, this.subscription);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.subscription);
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    },
    selectedSubscriptionPlan: function (): boolean {
      return _find(this.subscriptionPlans, {id: this.subscription.subscriptionPlanId});
    },
    subscriptionCost: function () {
      let subscriptionCost = 0;

      if (
        this.selectedSubscriptionPlan.requiresPayment &&
        this.selectedSubscriptionPlan.tiers
      ) {
        //  TODO: calculate completely dynamically / properly and don't hardcode!!!
        let numberOfFlatRateMembers = parseInt(this.selectedSubscriptionPlan.tiers[0].upTo);

        if (isNaN(numberOfFlatRateMembers)) {
          numberOfFlatRateMembers = 0;
        }

        let flatCost = parseInt(this.selectedSubscriptionPlan.tiers[0].flatAmountDecimal);

        if (isNaN(flatCost)) {
          flatCost = 0;
        }

        let numberOfAdditionalCostMembers = this.numberOfSubscriptionMembers - numberOfFlatRateMembers;
        let additionalMembersCost = 0;

        if (numberOfAdditionalCostMembers > 0) {
          let perMemberCost = parseInt(this.selectedSubscriptionPlan.tiers[1].unitAmountDecimal);

          if (isNaN(perMemberCost)) {
            perMemberCost = 0;
          }

          additionalMembersCost = numberOfAdditionalCostMembers * perMemberCost;

          if (isNaN(additionalMembersCost)) {
            additionalMembersCost = 0;
          }
        }

        subscriptionCost += (flatCost + additionalMembersCost);
      }

      subscriptionCost = this.formatCentsAsDollars(subscriptionCost);

      return `${subscriptionCost} per ${this.selectedSubscriptionPlan.interval}`;
    },
  },
  methods: {
    formatMonth: function (month) {
      let formattedMonth = month;

      if (formattedMonth < 10) {
        formattedMonth = "0" + formattedMonth;
      }

      return `${formattedMonth}`;
    },
    formatCentsAsDollars: function (cents) {
      return this.$root.formatCentsAsDollars(cents);
    },
    cardSuccessCallback: async function (data) {
      try {
        this.paymentMethodData = data.paymentMethod;

        await this.updatePaymentMethod(this.updatePaymentMethodEndpoint, this.paymentMethodData.id);

        this.paymentMethod = {
          cardBrand: this.paymentMethodData.card.brand,
          // TODO: set icon
          cardBrandIconMarkup: "",
          cardLast4: this.paymentMethodData.card.last4,
          cardExpMonth: this.paymentMethodData.card.exp_month,
          cardExpYear: this.paymentMethodData.card.exp_year
        };

        this.editingPaymentMethod = false;
      } catch (error) {
        this.$root.handleRequestError("saving", "payment", error);
      }
    },
    updatePaymentMethod: async function (updatePaymentMethodEndpoint: string, paymentMethodId: string) {
      await axios.post(
        updatePaymentMethodEndpoint,
        {
          customer: {
            payment_method_id: paymentMethodId
          }
        }
      );
    },
    cardCancelCallback: function (data) {
      this.editingPaymentMethod = false;
    },
    showPaymentsConfirmationModal: async function (paymentId) {
      await swal.fire({
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: "Go to payments page",
        focusConfirm: true,
        showConfirmButton: true,
        timer: 5000,
        timerProgressBar: true,
        title: "Extra confirmation is needed to process your payment.",
        text: "You will be redirected to payment page to confirm your payment.",
        icon: "warning",
        onClose: () => {
          this.allowedToLeave = true;

          // @ts-ignore
          Turbolinks.visit(`${this.subscriptionPaymentsBaseUrl}/${paymentId}`);
        }
      });
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      try {
        const response = await axios[this.apiHttpMethod](
          this.endpoint,
          this.subscription
        );

        if (!response.data && !response.data.id) {
          return;
        }

        this.allowedToLeave = true;

        let urlSlug = response.data.slug || response.data.id;
        // @ts-ignore
        Turbolinks.visit(`${this.endpointBase}/${urlSlug}`);
      } catch (error) {
        const errorResponseData = error.response.data;

        if (errorResponseData[this.incompletePaymentIntentIdErrorKey]) {
          let paymentId = errorResponseData[this.incompletePaymentIntentIdErrorKey];

          this.showPaymentsConfirmationModal(paymentId);
        } else {
          this.$root.handleRequestError("saving", this.modelDisplayName, error);
        }
      }
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function () {
    if (this.initialPaymentIntentNeedsReviewId) {
      this.showPaymentsConfirmationModal(this.initialPaymentIntentNeedsReviewId);
    }

    this.snapshotSubscriptionState = _cloneDeep(this.subscription);

    document.addEventListener(
      "turbolinks:before-visit",
      this.turboLinksBeforeVisitCallBack
    );
  },
  beforeDestroy() {
    document.removeEventListener(
      "turbolinks:before-visit",
      this.turboLinksBeforeVisitCallBack
    );
  }
};
