




































import _cloneDeep from "lodash/cloneDeep";

import Carousel from "./carousel.vue";

import SheetUtils from "./sheet_utils";

// TODO: DRY with SheetEdit vue component
export default {
  props: {
    initialSheetState: {
      type: Object,
      required: true
    },
    scale: {
      type: Array,
      required: true
    }
  },
  components: {
    Carousel
  },
  data: function (): object {
    let sheet = _cloneDeep(this.initialSheetState) || {
      title: "",
      pagesData: "",
      keyNote: "",
      keyMode: "",
      groupId: null,
      useFlatNotes: false
    };

    // Since the backend stores this as an int,
    // empty values will be passed as null, so convert
    // to empty string so we can default to the default option
    // which can't have a value of null
    if (!sheet.keyNote && sheet.keyNote !== 0) {
      sheet.keyNote = "";
    }

    return {
      intermediateKeyNote: 0,
      transposeAmount: 0,
      sheet: sheet
    };
  },
  computed: {
    pages: function () {
      let pages = {
        useFlatNotes: this.sheet.useFlatNotes,
        content: [""]
      };

      if (this.sheet.pagesData) {
        pages.content = JSON.parse(this.sheet.pagesData);
      }

      return pages;
    },
    sheetKey: function (): string {
      let sheetKey = "";

      if (typeof this.sheet.keyNote !== "undefined" && (this.sheet.keyNote || this.sheet.keyNote === 0)) {
        sheetKey = SheetUtils.getNoteDisplayNameFromNoteIndex(this.sheet.keyNote, this.scale, this.sheet.useFlatNotes);
      }

      if (this.sheet.keyMode) {
        sheetKey += ` ${this.sheet.keyMode}`;
      }

      return sheetKey;
    },
    transposeIndicator: function () {
      let transposeIndicator = null;

      if (this.transposeAmount) {
        transposeIndicator = SheetUtils.calculateTransposeIndicator(this.transposeAmount);
      }

      return transposeIndicator;
    },
  },
  methods: {
    doTranspose: function (transposeAmount: number): void {
      let newKeyNote = SheetUtils.correctTransposeScaleOverflow(this.sheet.keyNote + transposeAmount, this.scale.length);
      let intermediateKeyNote = this.sheet.keyNote;
      let correctedTransposeAmount = SheetUtils.calculateNewTransposeAmount(this.transposeAmount, transposeAmount);

      this.updateTransposeValues(newKeyNote, intermediateKeyNote, correctedTransposeAmount);
    },
    resetTranspose: function (): void {
      let newKeyNote = this.initialSheetState.keyNote;
      let intermediateKeyNote = this.sheet.keyNote;
      let transposeAmount = 0;

      this.updateTransposeValues(newKeyNote, intermediateKeyNote, transposeAmount);
    },
    updateTransposeValues: function (newKeyNote: number, intermediateKeyNote: number, transposeAmount: number): void {
      this.sheet.keyNote = newKeyNote;
      this.intermediateKeyNote = intermediateKeyNote;
      this.transposeAmount = transposeAmount;

      this.$root.$emit("transpose-notes", {newKeyNote: newKeyNote, intermediateKeyNote: intermediateKeyNote});
    }
  },
  mounted() {
    this.snapshotSheetState = _cloneDeep(this.sheet);
  }
};
