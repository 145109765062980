





































































































































import swal from "sweetalert2";

import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _reject from "lodash/reject";

export default {
  props: {
    initialSubscriptionState: Object,
    subscriptionRoles: {
      type: Array,
      required: true
    },
    subscriptionRolesDescriptionMarkup: {
      type: String,
      required: true
    },
    subscriptionOwnerRoleId: {
      type: Number,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    subscriptionInvitationEndpoint: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    canDelete: Boolean,
    profilePictures: {
      type: Object,
      required: true
    }
  },
  data: function (): object {
    let subscription = _cloneDeep(this.initialSubscriptionState) || {
      title: "",
      subscriptionMemberships: [],
      deletedSubscriptionMemberships: []
    };

    return {
      subscription: subscription,
      snapshotSubscriptionState: {},
      allowedToLeave: false
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotSubscriptionState, this.subscription);
    },
    subscriptionRoleTitleToDisplayTitleMapping: function (): Object {
      let subscriptionRoleTitleToDisplayTitleMapping = {};

      _forEach(this.subscriptionRoles, subscriptionRole => {
        subscriptionRoleTitleToDisplayTitleMapping[subscriptionRole.title] = subscriptionRole.displayTitle;
      });

      return subscriptionRoleTitleToDisplayTitleMapping;
    },
    addSubscriptionMembershipSubscriptionRoleOptions: function (): Object {
      let addSubscriptionMembershipSubscriptionRoleOptions = {};

      _forEach(this.subscriptionRoles, subscriptionRole => {
        if (subscriptionRole.id === this.subscriptionOwnerRoleId) {
          return;
        }

        addSubscriptionMembershipSubscriptionRoleOptions[subscriptionRole.id] = subscriptionRole.displayTitle;
      });

      return addSubscriptionMembershipSubscriptionRoleOptions;
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.subscription);
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    }
  },
  methods: {
    subscriptionMembershipUserIsSuspended: function (subscriptionMembership) {
      let userIsSuspended = false;

      if (
        subscriptionMembership.user &&
        subscriptionMembership.user.status &&
        subscriptionMembership.user.status === "suspended"
      ) {
        userIsSuspended = true;
      }

      return userIsSuspended;
    },
    getCardClassesForSubscriptionMembership: function (subscriptionMembership) {
      let cardClasses = "mb-4";

      if (this.subscriptionMembershipUserIsSuspended(subscriptionMembership)) {
        cardClasses += " border-danger";
      }

      return cardClasses;
    },
    getHeaderClassesForSubscriptionMembership: function (subscriptionMembership) {
      let headerClasses = "";

      if (this.subscriptionMembershipUserIsSuspended(subscriptionMembership)) {
        headerClasses = "bg-danger border-danger text-light";
      }

      return headerClasses;
    },
    editSubscriptionMembership: async function (subscriptionAssignment) {
      let roleOptions = {};

      _forEach(
        this.addSubscriptionMembershipSubscriptionRoleOptions,
        (subscriptionRoleDisplayTitle, subscriptionRoleId) => {
          if (parseInt(subscriptionRoleId) === subscriptionAssignment.subscriptionRole.id) {
            return;
          }

          roleOptions[subscriptionRoleId] = subscriptionRoleDisplayTitle;
        }
      );

      const {value: subscriptionRoleId} = await swal.fire({
        title: `Select a new role for ${subscriptionAssignment.user.username}.`,
        html: `<p>Their role is currently <strong>${this.subscriptionRoleTitleToDisplayTitleMapping[subscriptionAssignment.subscriptionRole.title]}</strong>.</p><small class="form-text text-muted text-left">The role determines what a user can do. ${this.subscriptionRolesDescriptionMarkup}</small>`,
        customClass: {
          input: "custom-select"
        },
        input: "select",
        inputOptions: roleOptions,
        inputPlaceholder: "Select a new role",
        showCancelButton: true,
        inputValidator: value => {
          return new Promise(resolve => {
            if (value) {
              resolve();
            } else {
              resolve("Please select a role.");
            }
          });
        }
      });

      if (!subscriptionRoleId) {
        return;
      }

      let subscriptionRole = _find(this.subscriptionRoles, {id: parseInt(subscriptionRoleId)});

      subscriptionAssignment.subscriptionRole = subscriptionRole;
    },
    removeSubscriptionMembership: function (subscriptionMembership): void {
      subscriptionMembership._destroy = true;

      // Since newly created subscriptionMemberships won't have an ID, filter by
      // user ID, since those will be unique
      this.subscription.subscriptionMemberships = _reject(
        this.subscription.subscriptionMemberships,
        loopSubscriptionMembership => {
          return loopSubscriptionMembership.user.id === subscriptionMembership.user.id;
        }
      );

      if (subscriptionMembership.id) {
        this.subscription.deletedSubscriptionMemberships.push(subscriptionMembership);
      }
    },
    undoRemoveSubscriptionMembership: function (subscriptionMembership): void {
      delete subscriptionMembership._destroy;

      // Since newly created subscriptionMemberships won't have an ID, filter by
      // user ID, since those will be unique
      this.subscription.deletedSubscriptionMemberships = _reject(
        this.subscription.deletedSubscriptionMemberships,
        loopSubscriptionMembership => {
          return loopSubscriptionMembership.user.id === subscriptionMembership.user.id;
        }
      );

      if (subscriptionMembership.id) {
        this.subscription.subscriptionMemberships.push(subscriptionMembership);
      }
    },
    setDeletedProperties: function () {
      this.subscription.deletedSubscriptionMemberships =
        this.subscription.deletedSubscriptionMemberships || [];
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      let subscriptionData = this.getPostDataObject(this.subscription);

      await this.$root.doSave.call(this, this.endpoint, subscriptionData, this.endpoint);
    },
    // TODO: computed?
    // Helper function to create the object with the needed key names
    // to post the nested associations
    // https://stackoverflow.com/questions/11196229/posting-json-with-association-data
    getPostDataObject(subscription) {
      let postData = _cloneDeep(subscription);

      if (
        postData.deletedSubscriptionMemberships &&
        postData.deletedSubscriptionMemberships.length > 0
      ) {
        postData.subscriptionMemberships = postData.subscriptionMemberships.concat(
          postData.deletedSubscriptionMemberships
        );
      }

      _forEach(
        postData.subscriptionMemberships,
        (subscriptionMembership, subscriptionMembershipKey) => {
          subscriptionMembership.userId = subscriptionMembership.user.id;
          delete subscriptionMembership.user;

          subscriptionMembership.subscriptionRoleId = subscriptionMembership.subscriptionRole.id;
          delete subscriptionMembership.subscriptionRole;
        }
      );

      postData.subscriptionMembershipsAttributes = postData.subscriptionMemberships;
      delete postData.deletedSubscriptionMemberships;
      delete postData.subscriptionMemberships;

      return postData;
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    // doDelete: async function () {
    //   await this.$root.doDelete.call(this);
    // },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  beforeMount: function () {
    this.setDeletedProperties();
  },
  mounted: function () {
    this.snapshotSubscriptionState = _cloneDeep(this.subscription);
    document.addEventListener(
      "turbolinks:before-visit",
      this.turboLinksBeforeVisitCallBack
    );
  },
  beforeDestroy() {
    document.removeEventListener(
      "turbolinks:before-visit",
      this.turboLinksBeforeVisitCallBack
    );
  }
};
