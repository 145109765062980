









































import CarouselItem from "./carousel_item.vue";

import CarouselUtils from "../carousel_utils";

export default {
  fadeInClass: "fade-in",
  fadeOutClass: "fade-out",
  // This should be the same as the css, plus a little extra
  fadeDurationInMs: 500,
  props: {
    slideIndex: {
      type: Number,
      default: 0
    },
    items: {
      type: Object,
      required: true
    },
    scale: {
      type: Array,
      required: true
    },
    transposeAmount: {
      type: Number,
      required: true
    }
  },
  components: {
    CarouselItem
  },
  data: function (): object {
    return {
      activeSlideIndex: this.slideIndex,
      transitionClass: this.$options.fadeInClass,
      initialTouchData: CarouselUtils.getFreshTouchDataObject(),
      interstitialXTransformAmount: 0
    }
  },
  computed: {
    numberOfItems: function (): Number {
      let numberOfItems = 0;

      if (this.items.content && this.items.content.length) {
        numberOfItems = this.items.content.length;
      }

      return numberOfItems;
    },
    canGoToPreviousSlide: function (): boolean {
      return CarouselUtils.checkIfCanGoToPreviousSlide(this.activeSlideIndex);
    },
    canGoToNextSlide: function (): boolean {
      return CarouselUtils.checkIfCanGoToNextSlide(this.activeSlideIndex, this.numberOfItems);
    },
    activeItem: function () {
      let activeItem = {
        useFlatNotes: this.items.useFlatNotes,
        content: ""
      };

      if (this.items.content[this.activeSlideIndex]) {
        activeItem.content = this.items.content[this.activeSlideIndex];
      }

      return activeItem;
    }
  },
  methods: {
    onTouchStart: function (event) {
      CarouselUtils.onTouchStart.call(this, event);
    },
    onTouchMove: function (event) {
      CarouselUtils.onTouchMove.call(this, event);
    },
    onTouchEnd: function (event) {
      CarouselUtils.touchFinishResetData.call(this, event);
    },
    onTouchCancel: function (event) {
      CarouselUtils.touchFinishResetData.call(this, event);
    },
    onSwipe: function (event) {
      CarouselUtils.onCarouselSwipe.call(this, event);
    },
    toPreviousSlide: function () {
      CarouselUtils.toPreviousSlide.call(this);
    },
    toNextSlide: function () {
      CarouselUtils.toNextSlide.call(this);
    },
    updateActiveSlideIndex: function (newSlideIndex) {
      CarouselUtils.updateActiveSlideIndex.call(this, newSlideIndex);
    },
  },
  created() {
    this.$root.$on("change-carousel-slide-index", (newSlideIndex) => {
      this.activeSlideIndex = newSlideIndex;
    });
  }
}
