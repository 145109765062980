




























































import TipFormBody from "./tip_form_body.vue";

export default {
  modalName: "tip-modal",
  components: {
    TipFormBody
  },
  props: {
    aboutPaymentsProcessingPath: {
      type: String,
      required: true
    },
    accountName: {
      type: String,
      required: true
    },
    feesDescription: {
      type: String,
      required: true
    },
    minTipAmount: {
      type: Number,
      required: true
    },
    maxTipAmount: {
      type: Number,
      required: true
    },
    platformFeesByRegion: {
      type: Object,
      required: true
    },
    stripeFeesByRegion: {
      type: Object,
      required: true
    },
    confirmTipBaseUrl: {
      type: String,
      required: true
    },
    showNameInButton: {
      type: Boolean,
      default: false
    },
    paymentIcon: {
      type: String,
      required: true
    },
    demoMode: {
      type: Boolean,
      default: false
    }
  },
  data: function (): object {
    return {};
  },
  computed: {},
  methods: {
    onTipFormBodyClose: function () {
      this.hideModal();
    },
    showModal: function () {
      this.$modal.show(this.$options.modalName);
    },
    hideModal: function () {
      this.$refs.form.resetTipAmount();
      this.$modal.hide(this.$options.modalName);
    },
  },
}
