








import _cloneDeep from "lodash/cloneDeep";

import {Editor} from "tiptap";

import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  Link,
  Strike,
  // Underline,
  // HorizontalRule,
  History
} from "tiptap-extensions";

import BodyEditor from "./body_editor.vue";

//@todo: dedupe with sheet editor wysiwyg.

export default {
  defaultEditorNodeContent: "<p><br></p>",
  props: {
    initialEditorContent: String,
    editorOnUpdateCallback: {
      type: Function,
      required: true
    }
  },
  components: {
    BodyEditor
  },
  data: function (): object {
    let editorContent = _cloneDeep(this.initialEditorContent) || "";

    return {
      editorContent: editorContent,
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new Heading({levels: [1, 2, 3, 4, 5, 6]}),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          new Link(),
          new Strike(),
          // new Underline(),
          // new HorizontalRule(),
          new History()
        ],
        onUpdate: ({getJSON, getHTML}) => {
          this.editorContent = getHTML();
          this.editorOnUpdateCallback(this.editorContent);
        },
      })
    };
  },
  mounted() {
    this.editor.setContent(this.editorContent);
  },
  beforeDestroy() {
    this.editor.destroy();
  }
}
