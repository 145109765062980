




















































































































































































































import draggable from "vuedraggable";

import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _filter from "lodash/filter";
import _forEach from "lodash/forEach";

import SelectAGroupWarning from "../select_a_group_warning.vue";
import StickyFooter from "../sticky_footer.vue";

export default {
  endpointBase: "/lists",
  addSheetModalName: "add-sheet",
  props: {
    initialListState: Object,
    groups: {
      type: Array,
      required: true
    },
    sheets: {
      type: Array,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    isDuplicate: Boolean
  },
  components: {
    draggable,
    SelectAGroupWarning,
    StickyFooter
  },
  data: function (): object {
    let list = _cloneDeep(this.initialListState) || {
      title: "",
      groupId: null,
      sheetSets: [],
      deletedSheetSets: []
    };

    return {
      list: list,
      snapshotListState: {},
      selectedGroup: null,
      addSheetModal: {
        sheetSet: null,
        selectedSheets: null
      },
      allowedToLeave: false
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotListState, this.list);
    },
    addSheetSetItemsOptions: function (): Array<Object> {
      let addSheetSetItemsOptions = [];
      let sheets = _filter(this.sheets, {groupId: this.list.groupId});

      _forEach(sheets, (sheet) => {
        addSheetSetItemsOptions.push(sheet);
      });

      return addSheetSetItemsOptions;
    },
    groupSelectOptions: function (): Array<Object> {
      return this.$root.groupSelectOptions(this.groups);
    },
    groupsById: function (): Object {
      return this.$root.groupsById(this.groups);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.list) && !this.isDuplicate;
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    }
  },
  methods: {
    onGroupSelect: async function (group) {
      // TODO: debug this isn't firing for first change when duplicating a list
      if (this.list.groupId) {
        const shouldChange = await this.$root.swalChangeAccessGroupConfirm.fire({
          text: "Changing \"Who can access this\" will reset the sets for this list (i.e. removes all the sets). Since different Groups have access to different sheets, changing the access group means the sheets have to be reset."
        });

        if (!shouldChange.value) {
          return;
        }
      }

      this.list.sheetSets = [];
      this.list.groupId = group.id;
    },
    onSheetSetsListDragEnd: function () {
      this.recalculateSheetSetsOrderProperty();
    },
    addSheetSet: async function () {
      let newSheetSet = {
        order: this.list.sheetSets.length + 1,
        sheetSetItems: [],
        deletedSheetSetItems: [],
        groupId: this.list.groupId
      };

      if (this.list.id) {
        // @ts-ignore
        newSheetSet.listId = this.list.id;
      }

      this.list.sheetSets.push(newSheetSet);
    },
    removeSheetSet: function (sheetSet) {
      sheetSet._destroy = true;

      this.list.sheetSets.splice((sheetSet.order - 1), 1);

      if (sheetSet.id) {
        this.list.deletedSheetSets.push(sheetSet);
      }

      this.recalculateSheetSetsOrderProperty();
    },
    recalculateSheetSetsOrderProperty: function () {
      _forEach(this.list.sheetSets, (sheetSet, sheetSetKey) => {
        this.list.sheetSets[sheetSetKey].order = sheetSetKey + 1;
      });
    },
    onSheetSetItemsListDragEnd: function () {
      this.recalculateSheetSetItemsOrderProperty();
    },
    showAddSheetModal: function (sheetSet) {
      this.$modal.show(this.$options.addSheetModalName);
      this.addSheetModal.sheetSet = sheetSet;
    },
    hideAddSheetModal: function () {
      this.$modal.hide(this.$options.addSheetModalName);
    },
    addSheetSetItems: async function () {
      if (!this.addSheetModal.selectedSheets) {
        return;
      }

      _forEach(this.addSheetModal.selectedSheets, (selectedSheet, selectedSheetKey) => {
        if (!selectedSheet.id) {
          return;
        }

        let sheet = _find(this.sheets, {"id": parseInt(selectedSheet.id)});

        if (!sheet) {
          return;
        }

        let newSheetSetItem = {
          order: this.addSheetModal.sheetSet.sheetSetItems.length + 1,
          sheetId: sheet.id,
          sheet: _cloneDeep(sheet),
          groupId: this.list.groupId
        };

        if (this.addSheetModal.sheetSet.id) {
          // @ts-ignore
          newSheetSetItem.sheetSetId = this.addSheetModal.sheetSet.id;
        }

        this.addSheetModal.sheetSet.sheetSetItems.push(newSheetSetItem);
      });

      this.addSheetModal.sheetSet = null;
      this.addSheetModal.selectedSheets = null;
      this.hideAddSheetModal();
    },
    removeSheetSetItem: function (sheetSet, sheetSetItem) {
      sheetSetItem._destroy = true;

      sheetSet.sheetSetItems.splice((sheetSetItem.order - 1), 1);

      if (sheetSetItem.id) {
        sheetSet.deletedSheetSetItems.push(sheetSetItem);
      }

      this.recalculateSheetSetItemsOrderProperty();
    },
    recalculateSheetSetItemsOrderProperty: function (sheetSet = null) {
      // Update the order property to match the order of the list
      if (sheetSet) {
        let sheetSetIndex = sheetSet.order - 1;
        _forEach(sheetSet.sheetSetItems, (sheetSetItem, sheetSetItemKey) => {
          this.list.sheetSets[sheetSetIndex].sheetSetItems[sheetSetItemKey].order = sheetSetItemKey + 1;
        });
      } else {
        _forEach(this.list.sheetSets, (sheetSet, sheetSetKey) => {
          _forEach(sheetSet.sheetSetItems, (sheetSetItem, sheetSetItemKey) => {
            this.list.sheetSets[sheetSetKey].sheetSetItems[sheetSetItemKey].order = sheetSetItemKey + 1;
          });
        });
      }
    },
    setDeletedProperties: function () {
      this.list.deletedSheetSets = this.list.deletedSheetSets || [];

      _forEach(this.list.sheetSets, (sheetSet, sheetSetKey) => {
        sheetSet.deletedSheetSetItems = sheetSet.deletedSheetSetItems || [];
      });
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      let listData = this.getPostDataObject(this.list);

      if (listData.title === this.snapshotListState.title && this.isDuplicate) {
        listData.title += " duplicate";
      }

      if (this.isDuplicate) {
        listData.id = null;
      }

      await this.$root.doSave.call(this, this.endpoint, listData);
    },
    // Helper function to create the object with the needed key names
    // to post the nested assoications
    // https://stackoverflow.com/questions/11196229/posting-json-with-association-data
    getPostDataObject(list) {
      // TODO: computed prop?
      let postData = _cloneDeep(list);

      if (postData.deletedSheetSets && postData.deletedSheetSets.length > 0) {
        postData.sheetSets = postData.sheetSets.concat(postData.deletedSheetSets);
      }

      _forEach(postData.sheetSets, (sheetSet, sheetSetKey) => {
        _forEach(sheetSet.sheetSetItems, (sheetSetItem, sheetSetItemKey) => {
          if (this.isDuplicate) {
            delete postData.sheetSets[sheetSetKey].sheetSetItems[sheetSetItemKey].id;
            delete postData.sheetSets[sheetSetKey].sheetSetItems[sheetSetItemKey].sheetSetId;
          }

          delete sheetSetItem.sheet;
        });

        if (postData.sheetSets[sheetSetKey].deletedSheetSetItems && postData.sheetSets[sheetSetKey].deletedSheetSetItems.length > 0) {
          postData.sheetSets[sheetSetKey].sheetSetItems = postData.sheetSets[sheetSetKey].sheetSetItems.concat(postData.sheetSets[sheetSetKey].deletedSheetSetItems);
        }

        if (this.isDuplicate) {
          delete postData.sheetSets[sheetSetKey].id;
          delete postData.sheetSets[sheetSetKey].listId;
        }

        postData.sheetSets[sheetSetKey].sheetSetItemsAttributes = postData.sheetSets[sheetSetKey].sheetSetItems;
        delete postData.sheetSets[sheetSetKey].deletedSheetSetItems;
        delete postData.sheetSets[sheetSetKey].sheetSetItems;
      });

      postData.sheetSetsAttributes = postData.sheetSets;
      delete postData.deletedSheetSets;
      delete postData.sheetSets;

      return postData;
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  beforeMount: function () {
    // Trigger this on mount in case the sheets in the set have non
    // contiguous order properties (e.g. sheet 1 is 1, sheet 2 is 2,
    // sheet 3 is 5, sheet 4 is 10 etc.)
    this.recalculateSheetSetsOrderProperty();
    this.recalculateSheetSetItemsOrderProperty();
    this.setDeletedProperties();

    if (this.isEdit) {
      // Setting the selectedGroup fallback to a null object will break the
      // validator (thinks a group has been selected), so only use a null
      // object when editing an existing record (and therefore the group can't
      // be changed).
      this.selectedGroup = this.groupsById[this.list.groupId] || {
        displayTitle: ""
      };
    } else {
      this.selectedGroup = this.groupsById[this.list.groupId] || null;
    }
  },
  mounted: function () {
    this.snapshotListState = _cloneDeep(this.list);
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy() {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
