import {Node} from "tiptap";

export default class PageBreak extends Node {

  get name() {
    return "page_break";
  }

  // TODO: dedupe with ruby model code.
  get markupClassName() {
    return "sheet-page-break";
  }

  get markupTag() {
    return `<hr class="${this.markupClassName}">`;
  }

  get defaultOptions() {
    return {};
  }

  get schema() {
    return {
      attrs: {},
      // selectable: true,
      // content: "block+",
      group: "block",
      parseDOM: [
        {tag: `hr.${this.markupClassName}`},
      ],
      toDOM: () => ["hr", {class: this.markupClassName}],
    };
  }

  commands({type, schema}) {
    return () => (state, dispatch) => dispatch(state.tr.replaceSelectionWith(type.create()))
  }
}
