






























































































































































































































































































































import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";

import {formatISO, parseISO} from "date-fns";

import SelectAGroupWarning from "../select_a_group_warning.vue";
import StickyFooter from "../sticky_footer.vue";

export default {
  addLocationModalName: "add-location",
  defaultDateRange: {
    start: null,
    end: null
  },
  props: {
    initialEventState: Object,
    groups: {
      type: Array,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    newLocationPath: {
      type: String,
      required: true
    },
    initialListsState: {
      type: Array,
      required: true
    },
    initialLocationsState: {
      type: Array,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    isDuplicate: Boolean,
    locationsEndpoint: {
      type: String,
      required: true
    },
    usStatesAndTerritories: {
      type: Object,
      required: true
    }
  },
  components: {
    SelectAGroupWarning,
    StickyFooter
  },
  data: function (): object {
    let event = _cloneDeep(this.initialEventState) || {
      title: "",
      groupId: null,
      body: "",
      startsAt: null,
      endsAt: null,
      allDay: false,
      showPublicPage: false,
      eventLocations: [{
        id: null,
        location: {
          id: null
        }
      }]
    };

    return {
      activeTab: 0,
      allowedToLeave: false,
      event: event,
      dateSelectionStarted: false,
      preventDateSelectionStartedFlagging: false,
      dateRange: this.getDateRangeFromEvent(event),
      selectedGroup: null,
      selectedList: null,
      selectedLocation: null,
      snapshotEventState: {},
      lists: _cloneDeep(this.initialListsState),
      locations: _cloneDeep(this.initialLocationsState)
    };
  },
  computed: {
    browserTimeZone: function () {
      let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // Attempt to humanize the name a bit
      // E.g. America/New_York = America: New York
      browserTimeZone = browserTimeZone
        .split("/")
        .join(": ")
        .split("_")
        .join(" ");

      return browserTimeZone;
    },
    datetimeFormat: function () {
      let datetimeFormat = "datetime";

      if (this.event.allDay) {
        datetimeFormat = "date";
      }

      return datetimeFormat;
    },
    listsSelectOptions: function () {
      let listsSelectOptions = _cloneDeep(this.lists);

      if (this.selectedGroup) {
        listsSelectOptions = _filter(this.lists, (list) => {
          return list.groupId === this.selectedGroup.id;
        });
      }

      return listsSelectOptions;
    },
    locationsSelectOptions: function () {
      let locationsSelectOptions = _cloneDeep(this.locations);

      if (this.selectedGroup) {
        locationsSelectOptions = _filter(this.locations, (location) => {
          return location.groupId === this.selectedGroup.id;
        });
      }

      return locationsSelectOptions;
    },
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotEventState, this.event);
    },
    groupSelectOptions: function (): Array<Object> {
      return this.$root.groupSelectOptions(this.groups);
    },
    groupsById: function (): Object {
      return this.$root.groupsById(this.groups);
    },
    listsById: function (): Object {
      return this.$root.groupsById(this.lists);
    },
    locationsById: function (): Object {
      return this.$root.groupsById(this.locations);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.event) && !this.isDuplicate;
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    }
  },
  methods: {
    showAddLocationModal: function (sheetSet) {
      this.$modal.show(this.$options.addLocationModalName);
    },
    hideAddLocationModal: function () {
      this.$modal.hide(this.$options.addLocationModalName);
    },
    editorOnUpdateCallback: function (updatedContent) {
      this.event.body = updatedContent;
    },
    onDatePickerInput: function (value) {
      this.dateSelectionStarted = false;
      this.preventDateSelectionStartedFlagging = true;
    },
    onDatePickerDayClick: function (day) {
      if (this.preventDateSelectionStartedFlagging) {
        this.preventDateSelectionStartedFlagging = false;
      } else {
        this.dateSelectionStarted = true;
      }
    },
    getListIdFromEventObject: function (event) {
      let listId = null;

      if (
        event.recordRelationships &&
        event.recordRelationships[0] &&
        event.recordRelationships[0].targetId &&
        event.recordRelationships[0].targetType === "List"
      ) {
        listId = event.recordRelationships[0].targetId
      }

      return listId;
    },
    getLocationIdFromEventObject: function (event) {
      let locationId = null;

      if (
        event.eventLocations &&
        event.eventLocations[0] &&
        event.eventLocations[0].location &&
        event.eventLocations[0].location.id
      ) {
        locationId = event.eventLocations[0].location.id
      }

      return locationId;
    },
    getDateRangeFromEvent: function (event) {
      let dateRange = _cloneDeep(this.$options.defaultDateRange);

      if (event.startsAt) {
        dateRange.start = parseISO(event.startsAt);
      }

      if (event.endsAt) {
        dateRange.end = parseISO(event.endsAt);
      } else {
        dateRange.end = dateRange.start;
      }

      return dateRange;
    },
    setEventDatesFromDateRange: function (event, dateRange) {
      event.startsAt = null;
      event.endsAt = null;

      if (dateRange.start) {
        event.startsAt = formatISO(dateRange.start);
      }

      if (dateRange.end) {
        event.endsAt = formatISO(dateRange.end);
      }

      return event;
    },
    calendarGoToToday: async function () {
      this.$refs.picker.$refs.calendar.move(new Date());
    },
    resetDateRange: function () {
      this.dateRange = _cloneDeep(this.$options.defaultDateRange);
      this.dateSelectionStarted = false;
    },
    onGroupSelect: async function (group) {
      this.event.groupId = group.id;
      this.selectedList = null;
      this.selectedLocation = null;
    },
    onSubmit: async function (event: object) {
      // Hack to prevent v-calendar from submitting the form
      // TODO: better way to do this?????
      // @ts-ignore
      if (event.submitter && event.submitter.parentElement && event.submitter.parentElement.className === "vc-am-pm") {
        return;
      }

      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      let eventData = _cloneDeep(this.event);

      eventData = this.setEventDatesFromDateRange(eventData, this.dateRange);

      if (eventData.title === this.snapshotEventState.title && this.isDuplicate) {
        eventData.title += " duplicate";
      }

      if (this.selectedList) {
        let recordRelationship = {
          targetId: this.selectedList.id,
          targetType: "List",
          originType: "Event"
        };

        if (
          eventData.recordRelationships &&
          eventData.recordRelationships[0] &&
          eventData.recordRelationships[0].id
        ) {
          //@ts-ignore;
          recordRelationship.id = eventData.recordRelationships[0].id;
        }

        if (this.event.id) {
          //@ts-ignore;
          recordRelationship.originId = this.event.id;
        }

        eventData.recordRelationshipsAttributes = [recordRelationship];
      } else if (
        this.initialEventState &&
        this.getListIdFromEventObject(this.initialEventState)
      ) {
        let recordRelationship = _cloneDeep(this.initialEventState.recordRelationships[0]);

        recordRelationship._destroy = true;

        eventData.recordRelationshipsAttributes = [recordRelationship];
      }

      if (this.selectedLocation) {
        let eventLocation = {
          locationId: this.selectedLocation.id
        };

        if (
          eventData.eventLocations &&
          eventData.eventLocations[0] &&
          eventData.eventLocations[0].id
        ) {
          //@ts-ignore;
          eventLocation.id = eventData.eventLocations[0].id;
        }

        if (this.event.id) {
          //@ts-ignore;
          eventLocation.eventId = this.event.id;
        }

        eventData.eventLocationsAttributes = [eventLocation];
      } else if (
        this.initialEventState &&
        this.getLocationIdFromEventObject(this.initialEventState)
      ) {
        let eventLocation = _cloneDeep(this.initialEventState.eventLocations[0]);

        eventLocation._destroy = true;

        eventData.eventLocationsAttributes = [eventLocation]
      }

      if (this.isDuplicate) {
        eventData.id = null;
      }

      await this.$root.doSave.call(this, this.endpoint, eventData);
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  created() {
    this.$root.$on("add-location-save", (newLocation) => {
      this.selectedLocation = newLocation;
      this.locations.push(newLocation);
      this.hideAddLocationModal();
    });

    this.$root.$on("add-location-cancel", () => {
      this.hideAddLocationModal();
    });
  },
  beforeMount: function () {
    if (this.isEdit) {
      // Setting the selectedGroup fallback to a null object will break the
      // validator (thinks a group has been selected), so only use a null
      // object when editing an existing record (and therefore the group can't
      // be changed).
      this.selectedGroup = this.groupsById[this.event.groupId] || {
        displayTitle: ""
      };
    } else {
      this.selectedGroup = this.groupsById[this.event.groupId] || null;
    }

    let listId = this.getListIdFromEventObject(this.event);
    this.selectedList = this.listsById[listId] || null;

    let locationId = this.getLocationIdFromEventObject(this.event);
    this.selectedLocation = this.locationsById[locationId] || null;
  },
  mounted: function () {
    this.snapshotEventState = _cloneDeep(this.event);

    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy() {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
