import GetConsumerIfLoggedIn from "./consumer";

let consumer = GetConsumerIfLoggedIn();

if (consumer) {
  consumer.subscriptions.create({channel: "NotificationCountChannel"}, {
    received(notification_count_data) {
      if (!notification_count_data) {
        return;
      }

      if (notification_count_data.notification_count) {
        document.querySelectorAll(".notification-count-badge").forEach((badgeElement) => {
          badgeElement.classList.remove("hide");
          badgeElement.textContent = notification_count_data.notification_count;
        });
      } else if (notification_count_data.chat_message_notification_count) {
        document.querySelectorAll(".chat-message-notification-count-badge").forEach((badgeElement) => {
          badgeElement.classList.remove("hide");
          badgeElement.textContent = notification_count_data.chat_message_notification_count;
        });
      }
    }
  });
}
