document.addEventListener("turbolinks:load", function () {
  document.addEventListener("click", function (event) {
    const tocSelector = ".help-page__table-of-contents";
    const tocClosedClass = "toc--closed";
    const tocOpenClass = "toc--open";

    // @ts-ignore;
    if (event.target.matches(".toc-close-button")) {
      document.querySelector(tocSelector).classList.remove(tocOpenClass);
      document.querySelector(tocSelector).classList.add(tocClosedClass);

      // @ts-ignore;
    } else if (event.target.matches(".toc-open-button")) {
      document.querySelector(tocSelector).classList.remove(tocClosedClass);
      document.querySelector(tocSelector).classList.add(tocOpenClass);
    }
  }, false);
});
