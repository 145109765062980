





































































import axios from "axios";

import _cloneDeep from "lodash/cloneDeep";

export default {
  props: {
    chatId: {
      type: Number,
      required: true
    },
    chatMessages: {
      type: Array,
      required: true
    },
    chatMessageCreateUrl: {
      type: String,
      required: true
    },
    chatMessageDataUrl: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    let chat_messages = _cloneDeep(this.chatMessages) || [];

    return {
      chat_messages: chat_messages,
      newChatMessage: {
        body: ""
      }
    };
  },
  channels: {
    ChatChannel: {
      connected() {
      },
      rejected() {
      },
      received(chat_message) {
        this.chat_messages.push(chat_message);
      },
      disconnected() {
      }
    }
  },
  computed: {},
  methods: {
    formatDate: function (dateString: string): string {
      return this.$parent.formatDate(dateString);
    },
    onChatMessageBodyEnter: function (event) {
      this.onChatMessageCreateSubmit();
    },
    onChatMessageBodyShiftEnter: function (event) {
      this.newChatMessage.body += "\n";
    },
    onChatMessageCreateSubmit: async function () {
      if (!this.newChatMessage.body || this.newChatMessage.body === "") {
        return;
      }

      try {
        const response = await axios["post"](
          this.chatMessageCreateUrl,
          {
            chat_id: this.chatId,
            body: this.newChatMessage.body
          }
        );

        if (!response.data) {
          return;
        }

        this.newChatMessage.body = "";
      } catch (error) {
        this.$root.handleRequestError("saving", "message", error);
      }
    }
  },
  mounted() {
    // @ts-ignore
    this.$cable.subscribe({
      // @ts-ignore
      channel: "ChatChannel",
      // @ts-ignore
      chat: this.chatId
    });

    window.scrollTo(0, document.body.scrollHeight);
  },
  updated() {
    window.scrollTo(0, document.body.scrollHeight);
  }
}
