



































































































































































































































































// @todo: dedupe with location-edit.vue

import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";

import StickyFooter from "../sticky_footer.vue";
import axios from "axios";

export default {
  defaultLocationObject: {
    title: "",
    body: "",
    street: "",
    street2: "",
    city: "",
    state: "",
    postalCode: "",
    groupId: null
  },
  props: {
    initialLocationState: Object,
    groups: {
      type: Array,
      required: true
    },
    groupId: Number,
    endpoint: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    usStatesAndTerritories: {
      type: Object,
      required: true
    },
  },
  components: {
    StickyFooter
  },
  data: function (): object {
    let location = this.getDefaultLocationInstance();

    return {
      activeTab: 0,
      location: location,
      selectedGroup: null,
      showAddress2Field: false,
      snapshotLocationState: {}
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotLocationState, this.location);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.location);
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    },
    usStatesAndTerritoriesSelectOptions: function () {
      let options = [];

      _forEach(this.usStatesAndTerritories, (name, code) => {
        options.push({
          code: code,
          name: name
        });
      });

      return options;
    },
    groupsById: function (): Object {
      return this.$root.groupsById(this.groups);
    }
  },
  methods: {
    editorOnUpdateCallback: function (updatedContent) {
      this.location.body = updatedContent;
    },
    getDefaultLocationInstance: function () {
      let location = _cloneDeep(this.$options.defaultLocationObject);

      location.groupId = this.groupId || null;

      return location;
    },
    resetLocationInstance: function () {
      this.location = this.getDefaultLocationInstance();
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      let locationData = _cloneDeep(this.location);

      if (locationData.state && locationData.state.code) {
        locationData.state = locationData.state.code;
      }

      try {
        const response = await axios[this.apiHttpMethod](
          this.endpoint,
          locationData
        );

        if (!response.data && !response.data.id) {
          return;
        }

        this.resetLocationInstance();

        this.$root.$emit("add-location-save", response.data);
      } catch (error) {
        this.$root.handleRequestError("saving", this.modelDisplayName, error);
      }
    },
    doCancel: async function () {
      this.resetLocationInstance();
      this.$root.$emit("add-location-cancel");
    }
  },
  beforeMount: function () {
    this.selectedGroup = this.groupsById[this.location.groupId] || null;
  },
  mounted: function () {
    this.snapshotLocationState = _cloneDeep(this.location);
  }
};
