import GetConsumerIfLoggedIn from "./consumer";

let consumer = GetConsumerIfLoggedIn();

if (consumer) {
  // TODO: figure out how to prevent turbolinks page change from
  // clobbering toasts. I.e. sheet save.
  const flashMessageRenderDelay = 1000;

  consumer.subscriptions.create({channel: "FlashMessageChannel"}, {
    received(flashMessage) {
      if (!flashMessage) {
        return;
      }

      setTimeout(function () {
        window.notyf[flashMessage.type](flashMessage.message);
      }, flashMessageRenderDelay);
    }
  });
}
