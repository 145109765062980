function getFreshTouchDataObject() {
  return {
    x: 0,
    y: 0
  };
}

function getSlideElement() {
  return document.querySelector(".slide");
}

function getOverFlowHideClass(): string {
  return "overflow-hidden";
}

function checkIfCanGoToPreviousSlide(activeSlideIndex: number): boolean {
  return activeSlideIndex > 0;
}

function checkIfCanGoToNextSlide(activeSlideIndex: number, numberOfItems: number): boolean {
  return (activeSlideIndex + 1) < numberOfItems;
}

// TODO: don't pass this context and decouple.
function onTouchStart(event) {
  const slideElement = getSlideElement();
  // @ts-ignore;
  slideElement.style.transform = null;

  this.interstitialXTransformAmount = 0;

  this.initialTouchData = {
    x: event.targetTouches[0].clientX,
    y: event.targetTouches[0].clientY
  };
}

// TODO: don't pass this context and decouple.
function onTouchMove(event) {
  const xThreshold = 50;
  let newX = event.targetTouches[0].clientX;
  let xDelta = this.initialTouchData.x - newX;
  let xDeltaInverse = xDelta * -1;
  let xDeltaAbsolute = Math.abs(xDelta);

  if (xDeltaAbsolute > xThreshold) {
    document.body.classList.add(getOverFlowHideClass());

    const slideElement = getSlideElement();

    let isValidPreviousSwipe = (xDeltaInverse > this.interstitialXTransformAmount) && this.canGoToPreviousSlide;
    let isValidNextSwipe = (xDeltaInverse < this.interstitialXTransformAmount) && this.canGoToNextSlide;

    if (isValidPreviousSwipe) {
      // Try to provide some animation smoothing by filling in the gaps.
      for (let i = this.interstitialXTransformAmount; i < xDeltaInverse; i++) {
        // @ts-ignore;
        slideElement.style.transform = `translateX(${i}px)`;
      }
    } else if (isValidNextSwipe) {
      for (let i = this.interstitialXTransformAmount; i > xDeltaInverse; i--) {
        // @ts-ignore;
        slideElement.style.transform = `translateX(${i}px)`;
      }
    }

    this.interstitialXTransformAmount = xDeltaInverse;
  }
}

// TODO: don't pass this context and decouple.
function touchFinishResetData(event) {
  document.body.classList.remove(getOverFlowHideClass());

  this.interstitialXTransformAmount = 0;
  this.initialTouchData = getFreshTouchDataObject();

  setTimeout(() => {
    const slideElement = getSlideElement();
    // @ts-ignore;
    slideElement.style.transform = null;
  }, this.$options.fadeDurationInMs / 3);
}

// TODO: don't pass this context and decouple.
function onCarouselSwipe(event): void {
  const deltaY = event.deltaY;
  const deltaYAbsoluteValue = Math.abs(deltaY);
  const changeThresholdY = 200;

  // Prevent scrolling down from firing off a slide change.
  if (deltaYAbsoluteValue > changeThresholdY) {
    // return;
  }

  const deltaX = event.deltaX;
  const deltaXAbsoluteValue = Math.abs(deltaX);
  const changeThresholdX = 125;

  if (deltaXAbsoluteValue < changeThresholdX) {
    return;
  }

  if (deltaX > 0) {
    this.toPreviousSlide();
  } else if (deltaX < 0) {
    this.toNextSlide();
  }
}

// TODO: don't pass this context and decouple.
function toPreviousSlide(): void {
  if (!this.canGoToPreviousSlide) {
    return;
  }

  this.transitionClass = this.$options.fadeOutClass;

  setTimeout(() => {
    this.updateActiveSlideIndex(this.activeSlideIndex - 1);
    this.transitionClass = this.$options.fadeInClass;
  }, this.$options.fadeDurationInMs);
}

// TODO: don't pass this context and decouple.
function toNextSlide(): void {
  if (!this.canGoToNextSlide) {
    return;
  }

  this.transitionClass = this.$options.fadeOutClass;

  setTimeout(() => {
    this.updateActiveSlideIndex(this.activeSlideIndex + 1);
    this.transitionClass = this.$options.fadeInClass;
  }, this.$options.fadeDurationInMs);
}

// TODO: don't pass this context and decouple.
function updateActiveSlideIndex(newSlideIndex: number): void {
  let max = this.numberOfItems - 1;
  let min = 0;

  if (newSlideIndex > max) {
    this.activeSlideIndex = max;
  } else if (newSlideIndex < min) {
    this.activeSlideIndex = min;
  } else {
    this.activeSlideIndex = newSlideIndex;
  }
}

export default {
  getFreshTouchDataObject: getFreshTouchDataObject,
  getSlideElement: getSlideElement,
  getOverFlowHideClass: getOverFlowHideClass,
  checkIfCanGoToPreviousSlide: checkIfCanGoToPreviousSlide,
  checkIfCanGoToNextSlide: checkIfCanGoToNextSlide,
  onTouchStart: onTouchStart,
  onTouchMove: onTouchMove,
  touchFinishResetData: touchFinishResetData,
  onCarouselSwipe: onCarouselSwipe,
  toPreviousSlide: toPreviousSlide,
  toNextSlide: toNextSlide,
  updateActiveSlideIndex: updateActiveSlideIndex
}
