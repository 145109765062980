






































































































































































































import {EditorContent, EditorMenuBubble, EditorMenuBar} from "tiptap";

export default {
  components: {
    EditorContent,
    EditorMenuBubble,
    EditorMenuBar
  },
  props: {
    editor: {
      type: Object,
      required: true
    }
  },
  data: function () {
    return {
      linkUrl: null,
      linkMenuIsActive: false,
    }
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({href: url})
      this.hideLinkMenu()
    }
  }
}
