

































































import axios from "axios";

export default {
  props: {
    amount: {
      required: true,
      type: Number
    },
    clientSecret: {
      required: true,
      type: String
    },
    id: {
      required: true,
      type: String
    },
    redirectPath: {
      type: String,
      required: true
    },
    initialStatus: {
      required: true,
      type: String
    },
    updatePaymentMethodEndpoint: {
      type: String
    }
  },
  data: function (): object {
    let status = this.initialStatus;

    return {
      status: status,
      stripe: null,
      errorMessage: "",
      paymentMethodData: null
    }
  },
  computed: {
    formattedPaymentAmount: function (): string {
      return this.$root.formatCentsAsDollars(this.amount);
    },
    title: function (): string {
      let title = `Please confirm your ${this.formattedPaymentAmount} payment`;

      switch (this.status) {
        case "canceled":
          title = "Payment cancelled";
          break;
        case "processing":
          title = "Payment is processing.";
          break;
        case "requires_payment_method":
          title = "Payment method needed.";
          break;
        case "succeeded":
          title = "Payment successful";
          break;
      }

      return title;
    },
    bodyText: function (): string {
      let bodyText = "Extra confirmation is needed to process your payment.";

      switch (this.status) {
        case "canceled":
          bodyText = "This payment was canceled.";
          break;
        case "processing":
          bodyText = "Your payment is being processed.";
          break;
        case "requires_payment_method":
          bodyText = "Please enter your payment method details.";
          break;
        case "succeeded":
          bodyText = "This payment was already successfully confirmed.";
          break;
      }

      return bodyText;
    },
    dataIsValid: function (): boolean {
      let dataIsValid = false;

      if (this.paymentMethodData && this.paymentMethodData.id) {
        dataIsValid = true;
      }

      return dataIsValid;
    },
    cancelledStatus: function (): boolean {
      return this.status === "cancelled";
    },
    requiresActionStatus: function (): boolean {
      return this.status === "requires_action";
    },
    requiresConfirmationStatus: function (): boolean {
      return this.status === "requires_confirmation";
    },
    requiresPaymentMethodStatus: function (): boolean {
      return this.status === "requires_payment_method";
    },
    processingStatus: function (): boolean {
      return this.status === "processing";
    },
    successStatus: function (): boolean {
      return this.status === "succeeded";
    },
    showStripeCard: function (): boolean {
      return this.requiresPaymentMethodStatus;
    },
    saveButtonText: function (): string {
      return `Pay ${this.formattedPaymentAmount}`;
    }
  },
  methods: {
    cardSuccessCallback: async function (data) {
      if (this.requiresPaymentMethodStatus) {
        try {
          this.paymentMethodData = data.paymentMethod;

          if (this.updatePaymentMethodEndpoint) {
            await this.updatePaymentMethod(this.updatePaymentMethodEndpoint, this.paymentMethodData.id);
          }
        } catch (error) {
          this.$root.handleRequestError("saving", "payment", error);
        }
      }

      // @ts-ignore;
      Turbolinks.visit(this.redirectPath);
    },
    updatePaymentMethod: async function (updatePaymentMethodEndpoint: string, paymentMethodId: string) {
      // TODO: dedupe with subscription/edit.vue
      await axios.post(
        updatePaymentMethodEndpoint,
        {
          customer: {
            payment_method_id: paymentMethodId
          }
        }
      );
    },
    cardErrorCallback: async function (data) {
      this.paymentMethodData = null;
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
  },
  mounted() {
    this.stripe = this.$root.getStripeInstance();

    try {
      if (this.requiresActionStatus) {
        this.stripe.confirmCardPayment(
          this.clientSecret,
          {
            setup_future_usage: "off_session"
          }
        ).then((result) => {
          if (result.error) {
            this.errorMessage = result.error.message;

            if (
              result.error &&
              result.error.payment_intent &&
              result.error.payment_intent.status
            ) {
              this.status = result.error.payment_intent.status;
            }
          } else {
            // @ts-ignore
            Turbolinks.visit(this.redirectPath);
          }
        });
      }
    } catch (error) {
      this.$root.handleRequestError("saving", "payment", error);
    }
  }
}
