




















export default {
  props: {
    cardClasses: {
      type: String,
      default: "mb-5"
    },
    headerClasses: {
      type: String,
      default: ""
    },
    headerTextClasses: {
      type: String,
      default: "mb-1"
    },
    bodyClasses: {
      type: String,
      default: ""
    },
    footerClasses: {
      type: String,
      default: "bg-none border-none pt-0"
    }
  }
}
