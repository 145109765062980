























import axios from "axios";
import HtmlDiff from "htmldiff-js";

import PageBreak from "./body_editor/page_break.ts";

export default {
  props: {
    latestVersionId: {
      type: Number,
      required: true
    },
    versionId: {
      type: Number,
      required: true
    },
    versionState: Object,
    changeset: {
      type: Object,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    }
  },
  computed: {
    isLatestVersion: function (): boolean {
      return this.latestVersionId === this.versionId;
    },
    title: function (): string {
      return this.doDiff("title");
    },
    pagesDiff: function (): string {
      // return this.doDiff("pagesData");
      const propertyName = "pagesData";
      let diff = "";

      if (this.versionState && this.versionState[propertyName]) {
        diff = this.pagesMarkup(this.versionState[propertyName]);
      }

      if (this.changeset[propertyName]) {
        let oldContent = "";

        if (this.changeset[propertyName][0]) {
          oldContent = this.pagesMarkup(this.changeset[propertyName][0]);
        }

        let newContent = this.pagesMarkup(this.changeset[propertyName][1]);

        diff = HtmlDiff.execute(oldContent, newContent);
      }

      return diff;
    },
    updatedDate: function (): string {
      return this.$parent.formatDate(this.changeset.updatedAt[1]);
    },
    endpoint: function (): string {
      let endpoint: string = `${window.location.pathname}/${this.versionId}`;

      return endpoint;
    }
  },
  methods: {
    pagesMarkup: function (pageData) {
      let pages = JSON.parse(pageData);

      return pages.join(PageBreak.prototype.markupTag);
    },
    doDiff: function (propertyName) {
      let diff = null;

      if (this.versionState && this.versionState[propertyName]) {
        diff = this.versionState[propertyName];
      }

      if (this.changeset[propertyName]) {
        let oldContent = this.changeset[propertyName][0] || "";
        let newContent = this.changeset[propertyName][1];

        diff = HtmlDiff.execute(oldContent, newContent);
      }

      return diff;
    },
    restoreVersion: async function () {
      try {
        const shouldRestore = await this.$root.swalGeneric.fire({
          title: "Are you sure you want to restore this version?",
          icon: "question",
          confirmButtonClass: "btn btn-primary mr-4",
          confirmButtonText: "Yes, restore it."
        });

        if (!shouldRestore.value) {
          return;
        }

        const response = await axios.post(this.endpoint);

        if (!response.data && !response.data.id) {
          return;
        }

        let responseUrlSlug = response.data.slug || response.data.id;

        // @ts-ignore
        Turbolinks.visit(`${this.endpointBase}/${responseUrlSlug}`);
      } catch (error) {
        this.$root.handleRequestError("restoring", this.modelDisplayName, error);
      }
    }
  }
};
