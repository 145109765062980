



































































































































































































































































































import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _forEach from "lodash/forEach";

import StickyFooter from "../sticky_footer.vue";

export default {
  props: {
    initialLocationState: Object,
    groups: {
      type: Array,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    usStatesAndTerritories: {
      type: Object,
      required: true
    },
  },
  components: {
    StickyFooter
  },
  data: function (): object {
    let location = _cloneDeep(this.initialLocationState) || {
      title: "",
      body: "",
      street: "",
      street2: "",
      city: "",
      state: "",
      postalCode: "",
      groupId: null
    };

    return {
      activeTab: 0,
      allowedToLeave: false,
      location: location,
      selectedGroup: null,
      showAddress2Field: false,
      snapshotLocationState: {}
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotLocationState, this.location);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.location);
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    },
    usStatesAndTerritoriesSelectOptions: function () {
      let options = [];

      _forEach(this.usStatesAndTerritories, (name, code) => {
        options.push({
          code: code,
          name: name
        });
      });

      return options;
    },
    groupSelectOptions: function (): Array<Object> {
      return this.$root.groupSelectOptions(this.groups);
    },
    groupsById: function (): Object {
      return this.$root.groupsById(this.groups);
    }
  },
  methods: {
    editorOnUpdateCallback: function (updatedContent) {
      this.location.body = updatedContent;
    },
    onGroupSelect: async function (group) {
      this.location.groupId = group.id;
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      let locationData = _cloneDeep(this.location);

      if (locationData.state && locationData.state.code) {
        locationData.state = locationData.state.code;
      }

      await this.$root.doSave.call(this, this.endpoint, locationData);
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    doDelete: async function () {
      await this.$root.doDelete.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  beforeMount: function () {
    if (this.isEdit) {
      // Setting the selectedGroup fallback to a null object will break the
      // validator (thinks a group has been selected), so only use a null
      // object when editing an existing record (and therefore the group can't
      // be changed).
      this.selectedGroup = this.groupsById[this.location.groupId] || {
        displayTitle: ""
      };
    } else {
      this.selectedGroup = this.groupsById[this.location.groupId] || null;
    }

    // Convert to the format that vue multiselect expects
    if (this.location.state) {
      let stateCode = this.location.state.toLowerCase();

      let stateValue = _find(this.usStatesAndTerritoriesSelectOptions, (selectOption) => {
        return selectOption.code === stateCode;
      });

      this.location.state = stateValue;
    }
  },
  mounted: function () {
    this.snapshotLocationState = _cloneDeep(this.location);

    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy() {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
