



































































































































































































import axios from "axios";

import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";
import _invert from "lodash/invert";

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    initialProfilePictureUrl: {
      type: String,
      default: null
    },
    initialUserProfileState: {
      type: Object,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    paymentSettingsPath: {
      type: String,
      required: true
    },
    profilePictureUploaderSettings: {
      type: Object,
      required: true
    },
    profileVisibilityOptions: {
      type: Object,
      required: true
    },
    timeZoneOptions: {
      type: Array,
      required: true
    },
    userHasStripeAccount: {
      type: Boolean
    }
  },
  data: function (): object {
    let userProfile = _cloneDeep(this.initialUserProfileState) || {
      body: "",
      profileVisibility: "public",
      timeZone: ""
    };

    let profileIsPrivate = this.profileVisibilityOptions[userProfile.profileVisibility] === 0;

    if (typeof userProfile.timeZone === "undefined") {
      userProfile.timeZone = "UTC";
    }

    return {
      allowedToLeave: false,
      croppedProfilePicture: null,
      croppedProfilePictureBlob: null,
      croppedProfilePictureUrl: null,
      isEditingProfilePicture: false,
      profileIsPrivate: profileIsPrivate,
      profilePictureRemoved: false,
      snapshotUserProfileState: {},
      userProfile: userProfile
    };
  },
  computed: {
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.userProfile);
    },
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotUserProfileState, this.userProfile);
    },
    profilePictureUrl: function (): string {
      let profilePictureUrl = "";

      if (this.profilePictureRemoved) {
        profilePictureUrl = "";
      } else if (this.croppedProfilePictureUrl) {
        profilePictureUrl = this.croppedProfilePictureUrl;
      } else if (this.initialProfilePictureUrl) {
        profilePictureUrl = this.initialProfilePictureUrl;
      }

      return profilePictureUrl;
    },
    profileVisibilityOptionsByValue: function (): object {
      return _invert(this.profileVisibilityOptions);
    }
  },
  // TODO: Move from watcher to computed property (and just use computed property in save action)
  watch: {
    "profileIsPrivate": function (newValue, oldValue) {
      this.userProfile.profileVisibility = this.$root.convertBooleanToEnum(!newValue, this.profileVisibilityOptionsByValue);

      if (newValue) {
        this.userProfile.showPublicPage = false;
      }
    }
  },
  methods: {
    editorOnUpdateCallback: function (updatedContent) {
      this.userProfile.body = updatedContent;
    },
    onDoneProfilePictureEdit: function (): void {
      this.croppedProfilePicture.generateBlob((blob) => {
        this.isEditingProfilePicture = false;

        if (!blob) {
          return;
        }

        this.croppedProfilePictureBlob = blob;

        if (this.croppedProfilePictureUrl) {
          URL.revokeObjectURL(this.croppedProfilePictureUrl);
        }

        this.croppedProfilePictureUrl = URL.createObjectURL(blob);
        this.profilePictureRemoved = false;
      });
    },
    onRemoveProfilePicture: function (): void {
      this.croppedProfilePicture = null;
      this.croppedProfilePictureBlob = null;
      this.croppedProfilePictureUrl = null;
      this.profilePictureRemoved = true;

    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      // Need to use FormData instead of JSON for uploading files
      const formData = new FormData;

      if (this.userProfile["body"]) {
        formData.set("user_profile[body]", this.userProfile["body"]);
      }

      if (this.userProfile["timeZone"]) {
        formData.set("user_profile[time_zone]", this.userProfile["timeZone"]);
      }

      if (this.userProfile["profileVisibility"]) {
        formData.set("user_profile[profile_visibility]", this.userProfile["profileVisibility"]);
      }

      if (typeof (this.userProfile["showPublicPage"]) !== "undefined") {
        formData.set("user_profile[show_public_page]", this.userProfile["showPublicPage"]);
      }

      if (this.croppedProfilePictureBlob) {
        formData.append(
          "user_profile[profile_picture]",
          this.croppedProfilePictureBlob
        );
      } else if (this.profilePictureRemoved) {
        formData.set(
          "user_profile[profile_picture]",
          null
        );
      }

      try {
        const response = await axios[this.apiHttpMethod](
          this.endpoint,
          formData
        );

        if (!response.data && !response.data.id) {
          return;
        }

        this.allowedToLeave = true;

        // @ts-ignore
        Turbolinks.visit(this.endpoint);
      } catch (error) {
        this.$root.handleRequestError("saving", this.modelDisplayName, error);
      }
    }
    ,
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    }
    ,
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function (): void {
    this.snapshotUserProfileState = _cloneDeep(this.userProfile);
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
  ,
  beforeDestroy(): void {
    URL.revokeObjectURL(this.croppedProfilePictureUrl);
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
}
;
