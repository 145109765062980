












































































import _cloneDeep from "lodash/cloneDeep";

export default {
  props: {
    currentUserId: {
      type: Number,
      required: true
    },
    initialSubscriptionInvitationState: {
      type: Object,
      required: true
    },
    actedOn: {
      type: Boolean,
      required: true
    },
    // Note that title is the result of a computed property (method) on the
    // active record model, hence passing it separately from the state.
    title: {
      type: String,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    toUserPath: {
      type: String,
      required: true
    },
    subscriptionPath: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    let subscriptionInvitation = _cloneDeep(this.initialSubscriptionInvitationState) || {
      status: "sent"
    };

    return {
      subscriptionInvitation: subscriptionInvitation,
      snapshotSubscriptionInvitationState: {},
      subscriptionInvitationFinalized: false,
    };
  },
  computed: {
    radioAcceptId: function (): string {
      //  html radios are weird, and since this component is reused on the
      //  the index page, the ID needs to be scoped so that the individual radios
      //  from each component don't mess with each other (treated as the same)
      return `subscription-invitation-${this.subscriptionInvitation.id}-edit__status__accept`;
    },
    radioDeclineId: function (): string {
      return `subscription-invitation-${this.subscriptionInvitation.id}-edit__status__decline`;
    },
    updatedDate: function (): string {
      return this.$parent.formatDate(this.subscriptionInvitation.updatedAt);
    },
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotSubscriptionInvitationState, this.subscriptionInvitation);
    },
    apiHttpMethod: function (): string {
      return "put";
    },
    titleText: function (): string {
      // TODO: don't do this client side
      let titleText = this.title;

      let invitationSplitText = " invitation to ";
      titleText = titleText.split(invitationSplitText);
      titleText[0] = `<a href="${this.toUserPath}">${titleText[0]}</a>`;

      let toTextSplitText = " from";
      let toText = titleText[1].split(toTextSplitText);
      toText[0] = `<a href="${this.subscriptionPath}">${toText[0]}</a>`;

      titleText[1] = toText.join(toTextSplitText);
      titleText = titleText.join(invitationSplitText);

      return titleText;
    },
    deleteButtonLabel: function (): string {
      let deleteButtonLabel = "Delete";

      if (this.actedOn) {
        deleteButtonLabel = "Can't delete once acted on"
      }

      return deleteButtonLabel;
    }
  },
  methods: {
    doSave: async function () {
      let subscriptionInvitationData = _cloneDeep(this.subscriptionInvitation);

      if ((typeof subscriptionInvitationData.status !== undefined) && (subscriptionInvitationData.status === null)) {
        delete subscriptionInvitationData.status;
      }

      await this.$root.doSave.call(this, this.endpoint, subscriptionInvitationData, this.endpointBase);
    },
    doDelete: async function () {
      await this.$root.doDelete.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function (): void {
    this.snapshotSubscriptionInvitationState = _cloneDeep(this.subscriptionInvitation);
    this.subscriptionInvitationFinalized = this.snapshotSubscriptionInvitationState.status === "accepted";
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy(): void {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
