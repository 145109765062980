import _map from "lodash/map";
import _unescape from "lodash/unescape";

import SheetUtils from "../sheet_utils";

/**
 * This parses a string of markup for chords and notes, converts the chords and notes and then returns the markup
 * string.
 *
 * @param markup
 * @param lettersInScale
 * @param scaleByNoteName
 */
export default function (markup: string, scale: Array<object>, scaleByNoteName: object, lettersInScale: Array<string>, useFlatNotes: boolean): string {
  // TODO: break this down into smaller functions

  const noteTagSplitToken = '<span class="note"';
  const noteTagSplitEndToken = "</span>";
  const noteDetectToken = " data-note";
  const chordDetectToken = 'data-note-is-chord="true"';
  const chordOpenToken = "[";
  const chordCloseToken = "]";
  const chordOpenProcessedToken = "!~~|";
  const chordCloseProcessedToken = "|~~!";

  // Need to mark the existing parsed chords so they aren't
  // processed twice.
  let existingChordsTokens = markup.split(noteTagSplitToken);

  existingChordsTokens = _map(existingChordsTokens, function (tokenString) {
    if (!tokenString.startsWith(noteDetectToken)) {
      return tokenString;
    }

    tokenString = tokenString.split(`${chordCloseToken}${noteTagSplitEndToken}`);

    if (tokenString[0].indexOf(chordDetectToken) !== -1) {
      tokenString[0] = tokenString[0].replace(chordOpenToken, chordOpenProcessedToken);

      tokenString = tokenString.join(`${chordCloseProcessedToken} ${noteTagSplitEndToken}`);
    } else {
      tokenString = tokenString.join(`${chordCloseToken}${noteTagSplitEndToken}`);
    }

    return tokenString;
  });

  markup = existingChordsTokens.join(noteTagSplitToken);

  const sharpSymbol = "♯";
  const flatSymbol = "♭";
  const fakeSharpSymbol = "#";
  const fakeFlatSymbol = "b";

  let newChordsTokens = markup.split(chordOpenToken);

  newChordsTokens = _map(newChordsTokens, (tokenString) => {
    if (tokenString.indexOf(chordCloseToken) === -1) {
      return tokenString;
    }

    let originalTokenString = tokenString;

    // First remove trailing white space (since the chord has that embedded)
    // then tokenize
    tokenString = tokenString.split(chordCloseToken);

    let chord = tokenString[0];
    let chordNote = chord[0].toUpperCase();

    // @ts-ignore;
    if (!lettersInScale.includes(chordNote)) {
      // If we can't detect a chord right away, parse it from markup
      // in case some <strong> or other tags are in there
      // https://davidwalsh.name/convert-html-stings-dom-nodes
      let parsedChordText = document.createRange().createContextualFragment(chord);

      if (parsedChordText && parsedChordText.textContent) {
        // @ts-ignore;
        parsedChordText = parsedChordText.textContent;
        let parsedChordNoteText = parsedChordText[0].toUpperCase();

        // @ts-ignore;
        if (lettersInScale.includes(parsedChordNoteText)) {
          chord = parsedChordText;
          chordNote = parsedChordNoteText;
        } else {
          return originalTokenString;
          // return `${chordOpenToken}${originalTokenString}`;
        }
      }
    }

    let chordModifier = chord.substring(1);

    if (chord[1]) {
      if (chord[1].toLowerCase() === fakeSharpSymbol) {
        chordNote += sharpSymbol;
        chordModifier = chordModifier.substring(1);
      } else if (chord[1].toLowerCase() === fakeFlatSymbol) {
        chordNote += flatSymbol;
        chordModifier = chordModifier.substring(1);
      }
    }

    if (!scaleByNoteName[chordNote]) {
      return originalTokenString;
    }

    chordNote = SheetUtils.getNoteDisplayNameFromNoteIndex(scaleByNoteName[chordNote].id, scale, useFlatNotes);

    let chordObject = {
      noteId: scaleByNoteName[chordNote].id,
      noteName: chordNote,
      modifier: chordModifier,
      isChord: true,
    };

    tokenString[0] = `<span class="note" data-note-note-id="${chordObject.noteId}" data-note-note-name="${chordObject.noteName}" data-note-note-modifier="${chordObject.modifier}" data-note-is-chord="true">[${chordObject.noteName}${chordObject.modifier}] `;
    tokenString = tokenString.join(noteTagSplitEndToken);

    return tokenString;
  });

  markup = newChordsTokens.join("");

  // Put the existing chords back
  markup = markup
    .split(chordOpenProcessedToken)
    .join(chordOpenToken)
    .split(chordCloseProcessedToken)
    .join(chordCloseToken);

  const noteDelimiterToken = "^";

  let notesTokens = markup.split(noteDelimiterToken);
  const notesTokensLoopLength = notesTokens.length;

  for (let i = 0; i < notesTokensLoopLength; i++) {
    // Anything between the note delimiter tokens have an
    // odd index, thus skip even iterations
    if ((i % 2 === 0) || (!notesTokens[i])) {
      continue;
    }

    let notes = notesTokens[i];

    // @ts-ignore;
    notes = notes.split(" ");

    notes = _map(notes, (noteToken) => {
      let note = noteToken[0].toUpperCase();

      if (noteToken[1]) {
        if (noteToken[1].toLowerCase() === fakeSharpSymbol) {
          note += sharpSymbol;
        } else if (noteToken[1].toLowerCase() === fakeFlatSymbol) {
          note += flatSymbol;
        }
      }

      if (!scaleByNoteName[note]) {
        return noteToken;
      }

      let noteObject = {
        noteId: scaleByNoteName[note].id,
        noteName: note
      };

      return `<span class="note" data-note-note-id="${noteObject.noteId}" data-note-note-name="${noteObject.noteName}" data-note-note-modifier="" data-note-is-chord="">${noteObject.noteName} ${noteTagSplitEndToken}`;
    });

    // @ts-ignore;
    notesTokens[i] = notes.join(" ");
  }

  markup = notesTokens.join("");
  markup = _unescape(markup);

  return markup;
};
