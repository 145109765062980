
























import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";

import {Editor, EditorContent} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  // Link,
  Strike,
  // Underline,
  // HorizontalRule,
  History
} from "tiptap-extensions";

import Note from "./../sheets/body_editor/note";
import PageBreak from "./../sheets/body_editor/page_break";

// TODO: DRY with SheetEdit vue component
export default {
  defaultEditorNodeContent: "<p><br></p>",
  props: {
    initialSheetState: {
      type: Object,
      required: true
    },
    scale: {
      type: Array,
      required: true
    }
  },
  components: {
    EditorContent
  },
  data: function (): object {
    let sheet = _cloneDeep(this.initialSheetState) || {
      title: "",
      body: "",
      keyNote: "",
      keyMode: "",
      groupId: null,
      useFlatNotes: false
    };

    // Since the backend stores this as an int,
    // empty values will be passed as null, so convert
    // to empty string so we can default to the default option
    // which can't have a value of null
    if (!sheet.keyNote && sheet.keyNote !== 0) {
      sheet.keyNote = "";
    }

    return {
      intermediateKeyNote: 0,
      transposeOffsetAmount: 0,
      sheet: sheet,
      editor: new Editor({
        editable: false,
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new Heading({levels: [1, 2, 3]}),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          // new Link(),
          new Strike(),
          // new Underline(),
          // new HorizontalRule(),
          new History(),

          new Note(),
          new PageBreak()
        ],
        onUpdate: ({getJSON, getHTML}) => {
          this.updatePagesData();
        }
      })
    };
  },
  computed: {
    pages: function (): Array<string> {
      let pages = [""];

      if (this.sheet.pagesData) {
        pages = JSON.parse(this.sheet.pagesData);
      }

      return pages;
    },
    pagesMarkup: function (): string {
      return this.pages.join(PageBreak.prototype.markupTag);
    },
    displayScale: function (): Array<object> {
      return this.scale.map((scaleNote) => {
        let note = _cloneDeep(scaleNote);

        note.displayName = note.name;

        if (note.isSharpOrFlat) {
          if (this.sheet.useFlatNotes) {
            note.displayName = note.flatName;
          } else {
            note.displayName = note.sharpName;
          }
        }

        return note;
      });
    },
    scaleByNoteName: function () {
      let scaleByNoteName = {};

      _forEach(this.scale, function (note) {
        if (note.isSharpOrFlat) {
          scaleByNoteName[note.flatName] = note;
          scaleByNoteName[note.sharpName] = note;
        } else {
          scaleByNoteName[note.name] = note;
        }
      });

      return scaleByNoteName;
    },
    sheetKey: function (): string {
      let sheetKey = "";

      if (typeof this.sheet.keyNote !== "undefined" && (this.sheet.keyNote || this.sheet.keyNote === 0)) {
        sheetKey = this.displayScale[this.sheet.keyNote].displayName;
      }

      if (this.sheet.keyMode) {
        sheetKey += ` ${this.sheet.keyMode}`;
      }

      return sheetKey;
    },
    displayTitle: function (): string {
      let displayTitle = this.sheet.title;

      if (this.order) {
        displayTitle = `${this.order} ${displayTitle}`;
      }

      if (this.sheetSetOrder) {
        displayTitle = `${this.sheetSetOrder}.${displayTitle}`;
      }

      return displayTitle;
    },
    transposeIndicator: function () {
      let transposeIndicator = null;

      if (this.transposeOffsetAmount) {
        if (this.transposeOffsetAmount > 0) {
          transposeIndicator = `+${this.transposeOffsetAmount}`;
        } else if (this.transposeOffsetAmount < 0) {
          transposeIndicator = `${this.transposeOffsetAmount}`;
        }
      }

      return transposeIndicator;
    },
  },
  watch: {
    "sheet.keyNote": function (newValue, oldValue) {
      this.intermediateKeyNote = oldValue;
      this.transposeNotes();
    },
    "displayScale": function (newValue, oldValue) {
      this.intermediateKeyNote = this.sheet.keyNote;
      this.transposeNotes();
    },
  },
  methods: {
    doTranspose: function (transposeAmount: number): void {
      this.sheet.keyNote = this.correctTransposeScaleOverflow(this.sheet.keyNote + transposeAmount);
      this.updateTransposeOffsetAmount(transposeAmount);
    },
    resetTranspose: function (): void {
      this.sheet.keyNote = this.initialSheetState.keyNote;
      this.transposeOffsetAmount = 0;
    },
    transposeNotes: function () {
      let editorContentJSON = this.editor.getJSON();
      let notes = [];

      const traverseEditorContent = (node) => {
        for (let key in node) {
          if (node[key] && typeof node[key] === "object") {
            traverseEditorContent(node[key])
          } else if (node.type === "note") {
            let nodeId = parseInt(node.attrs.noteId);
            let transposedNoteId = this.correctTransposeScaleOverflow(nodeId + transposeAmount);
            let transposedNoteName = this.displayScale[transposedNoteId].displayName;

            node.attrs.noteId = transposedNoteId;
            node.attrs.noteName = transposedNoteName;
          }
        }
      };

      let transposeAmount = this.sheet.keyNote - this.intermediateKeyNote;

      traverseEditorContent(editorContentJSON);

      this.updateEditorContent(editorContentJSON);
    },
    correctTransposeScaleOverflow: function (transposedNoteId): number {
      if (transposedNoteId < 0) {
        transposedNoteId = this.displayScale.length + transposedNoteId;
      } else if (transposedNoteId > (this.displayScale.length - 1)) {
        transposedNoteId = transposedNoteId - this.displayScale.length;
      }

      return transposedNoteId;
    },
    updateEditorContent: function (content) {
      this.editor.clearContent();
      this.editor.setContent(content);
      this.updatePagesData();
    },
    updatePagesData: function () {
      let editorHtml = this.editor.getHTML();
      // TODO: determine why the <br> gets stripped out and has to be readded.
      editorHtml = editorHtml.split('<p></p>').join(this.$options.defaultEditorNodeContent);

      let pages = editorHtml.split(PageBreak.prototype.markupTag);

      this.sheet.pagesData = JSON.stringify(pages);
    },
    /**
     * This is used to correct any overflows, relative to the starting key.
     *
     * @param transposeAmount
     */
    updateTransposeOffsetAmount: function (transposeAmount: number) {
      let newTransposeOffsetAmount = this.transposeOffsetAmount + transposeAmount;

      if (newTransposeOffsetAmount >= 12) {
        newTransposeOffsetAmount -= 12;
      } else if (newTransposeOffsetAmount <= -12) {
        newTransposeOffsetAmount += 12;
      }

      this.transposeOffsetAmount = newTransposeOffsetAmount;
    }
  },
  mounted() {
    this.editor.setContent(this.pagesMarkup);
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
