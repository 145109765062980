





































































































































import swal from "sweetalert2";

import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _reject from "lodash/reject";

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    groupInvitationEndpoint: {
      type: String,
      required: true
    },
    groupOwnerRoleId: {
      type: Number,
      required: true
    },
    groupRoles: {
      type: Array,
      required: true
    },
    groupRolesDescriptionMarkup: {
      type: String,
      required: true
    },
    initialGroupState: Object,
    modelDisplayName: {
      type: String,
      required: true
    },
    profilePictures: {
      type: Object,
      required: true
    }
  },
  data: function (): object {
    let group = _cloneDeep(this.initialGroupState) || {
      title: "",
      groupMemberships: [],
      deletedGroupMemberships: []
    };

    return {
      allowedToLeave: false,
      group: group,
      snapshotGroupState: {}
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotGroupState, this.group);
    },
    groupRoleTitleToDisplayTitleMapping: function (): Object {
      let groupRoleTitleToDisplayTitleMapping = {};

      _forEach(this.groupRoles, groupRole => {
        groupRoleTitleToDisplayTitleMapping[groupRole.title] = groupRole.displayTitle;
      });

      return groupRoleTitleToDisplayTitleMapping;
    },
    addGroupMembershipGroupRoleOptions: function (): Object {
      let addGroupMembershipGroupRoleOptions = {};

      _forEach(this.groupRoles, groupRole => {
        if (groupRole.id === this.groupOwnerRoleId) {
          return;
        }

        addGroupMembershipGroupRoleOptions[groupRole.id] = groupRole.displayTitle;
      });

      return addGroupMembershipGroupRoleOptions;
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.group);
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    }
  },
  methods: {
    groupMembershipUserIsSuspended: function (groupMembership) {
      let userIsSuspended = false;

      if (
        groupMembership.user &&
        groupMembership.user.status &&
        groupMembership.user.status === "suspended"
      ) {
        userIsSuspended = true;
      }

      return userIsSuspended;
    },
    getCardClassesForGroupMembership: function (groupMembership) {
      let cardClasses = "mb-4";

      if (this.groupMembershipUserIsSuspended(groupMembership)) {
        cardClasses += " border-danger";
      }

      return cardClasses;
    },
    getHeaderClassesForGroupMembership: function (groupMembership) {
      let headerClasses = "";

      if (this.groupMembershipUserIsSuspended(groupMembership)) {
        headerClasses = "bg-danger border-danger text-light";
      }

      return headerClasses;
    },
    editGroupMembership: async function (groupAssignment) {
      let roleOptions = {};

      _forEach(
        this.addGroupMembershipGroupRoleOptions,
        (groupRoleDisplayTitle, groupRoleId) => {
          if (parseInt(groupRoleId) === groupAssignment.groupRole.id) {
            return;
          }

          roleOptions[groupRoleId] = groupRoleDisplayTitle;
        }
      );

      const {value: groupRoleId} = await swal.fire({
        title: `Select a new role for ${groupAssignment.user.username}.`,
        html: `<p>Their role is currently <strong>${this.groupRoleTitleToDisplayTitleMapping[groupAssignment.groupRole.title]}</strong>.</p><small class="form-text text-muted text-left">The role determines what a user can do. ${this.groupRolesDescriptionMarkup}</small>`,
        customClass: {
          input: "custom-select"
        },
        input: "select",
        inputOptions: roleOptions,
        inputPlaceholder: "Select a new role",
        showCancelButton: true,
        inputValidator: value => {
          return new Promise(resolve => {
            if (value) {
              resolve();
            } else {
              resolve("Please select a role.");
            }
          });
        }
      });

      if (!groupRoleId) {
        return;
      }

      groupAssignment.groupRole = _find(this.groupRoles, {id: parseInt(groupRoleId)});
    },
    removeGroupMembership: function (groupMembership): void {
      groupMembership._destroy = true;

      // Since newly created groupMemberships won't have an ID, filter by
      // user ID, since those will be unique
      this.group.groupMemberships = _reject(
        this.group.groupMemberships,
        loopGroupMembership => {
          return loopGroupMembership.user.id === groupMembership.user.id;
        }
      );

      if (groupMembership.id) {
        this.group.deletedGroupMemberships.push(groupMembership);
      }
    },
    undoRemoveGroupMembership: function (groupMembership): void {
      delete groupMembership._destroy;

      // Since newly created groupMemberships won't have an ID, filter by
      // user ID, since those will be unique
      this.group.deletedGroupMemberships = _reject(
        this.group.deletedGroupMemberships,
        loopGroupMembership => {
          return loopGroupMembership.user.id === groupMembership.user.id;
        }
      );

      if (groupMembership.id) {
        this.group.groupMemberships.push(groupMembership);
      }
    },
    setDeletedProperties: function () {
      this.group.deletedGroupMemberships =
        this.group.deletedGroupMemberships || [];
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      let group_data = this.getPostDataObject(this.group);

      await this.$root.doSave.call(this, this.endpoint, group_data);
    },
    // TODO: computed?
    // Helper function to create the object with the needed key names
    // to post the nested associations
    // https://stackoverflow.com/questions/11196229/posting-json-with-association-data
    getPostDataObject(group) {
      let postData = _cloneDeep(group);

      if (
        postData.deletedGroupMemberships &&
        postData.deletedGroupMemberships.length > 0
      ) {
        postData.groupMemberships = postData.groupMemberships.concat(
          postData.deletedGroupMemberships
        );
      }

      _forEach(
        postData.groupMemberships,
        (groupMembership, groupMembershipKey) => {
          groupMembership.userId = groupMembership.user.id;
          delete groupMembership.user;

          groupMembership.groupRoleId = groupMembership.groupRole.id;
          delete groupMembership.groupRole;
        }
      );

      postData.groupMembershipsAttributes = postData.groupMemberships;
      delete postData.deletedGroupMemberships;
      delete postData.groupMemberships;

      return postData;
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    // doDelete: async function () {
    //   await this.$root.doDelete.call(this);
    // },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  beforeMount: function () {
    this.setDeletedProperties();
  },
  mounted: function () {
    this.snapshotGroupState = _cloneDeep(this.group);
    document.addEventListener(
      "turbolinks:before-visit",
      this.turboLinksBeforeVisitCallBack
    );
  },
  beforeDestroy() {
    document.removeEventListener(
      "turbolinks:before-visit",
      this.turboLinksBeforeVisitCallBack
    );
  }
};
