






export default {
  props: {
    fieldName: {
      type: String,
      required: true
    }
  }
}
