/******************************************
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * NOTE: this should be kept in sync with
 *       app/services/stripe_utils/fees.rb
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *****************************************/

function calculateAmountWithFeesIncluded(desiredPaymentAmount: number, platformFeesByRegion: object, stripeFeesByRegion: object, region: string = "us") {
  // See: https://support.stripe.com/questions/passing-the-stripe-fee-on-to-customers
  let fixedFees = stripeFeesByRegion[region]["fixedFeeInCents"] + platformFeesByRegion[region]["fixedFeeInCents"];
  let percentFees = stripeFeesByRegion[region]["percentFee"] + platformFeesByRegion[region]["percentFee"];
  let amountWithFeesIncluded = (desiredPaymentAmount + fixedFees) / (1 - percentFees);

  // NOTE: Ruby's .to_i does *not* round up, merely truncates, so don't
  // use round, rather truncate. Also, Stripe doesn't seem to round up either.
  //
  // See: https://apidock.com/ruby/Float/to_i
  // See: https://stackoverflow.com/a/8946188
  //
  // amountWithFeesIncluded = Math.round(amountWithFeesIncluded);
  amountWithFeesIncluded = Math.floor(amountWithFeesIncluded);

  return amountWithFeesIncluded;
}

export default {
  calculateAmountWithFeesIncluded: calculateAmountWithFeesIncluded
}
