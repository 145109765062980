// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import {createConsumer} from "@rails/actioncable"

const GetConsumerIfLoggedIn = () => {
  let consumer = null;

  if (document.cookie.indexOf("signed_in=1") !== -1) {
    consumer = createConsumer();
  }

  return consumer;
};

export default GetConsumerIfLoggedIn;
