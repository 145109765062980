

























































































import _forEach from "lodash/forEach";

import Carousel from "./carousel.vue";

export default {
  tableOfContentsModalName: "table-of-contents",
  props: {
    sheetSets: {
      type: Array,
      required: true
    },
    scale: {
      type: Array,
      required: true
    }
  },
  components: {
    Carousel
  },
  data: function (): object {
    return {
      slideIndex: 0,
      tableOfContentsModalHidden: true
    };
  },
  computed: {
    sheetSetsWithParsedSheetPages: function () {
      let sheetSetItemsWithParsedPages = [];

      _forEach(this.sheetSets, function (sheetSet) {
        _forEach(sheetSet.sheetSetItems, function (sheetSetItem, key) {
          sheetSet.sheetSetItems[key].sheet.pages = JSON.parse(sheetSetItem.sheet.pagesData)
        });
        sheetSetItemsWithParsedPages.push(sheetSet);
      });

      return sheetSetItemsWithParsedPages;
    },
    flatListOfSheetSetItems: function () {
      let flatListOfSheetSetItems = [];

      _forEach(this.sheetSetsWithParsedSheetPages, function (sheetSet) {
        _forEach(sheetSet.sheetSetItems, function (sheetSetItem) {
          _forEach(sheetSetItem.sheet.pages, function (page, pageIndex) {
            let buffer = {
              "sheetSetOrder": sheetSet.order,
              "id": sheetSetItem.id,
              "order": sheetSetItem.order,
              "sheet": sheetSetItem.sheet,
              "page": page,
              "pageNumber": (pageIndex + 1),
              "sheetId": sheetSetItem.sheetId,
              "sheetSetId": sheetSetItem.sheetSetId,
            };

            buffer.sheet.intermediateKeyNote = 0;
            buffer.sheet.transposeAmount = 0;

            flatListOfSheetSetItems.push(buffer);
          });
        });
      });

      return flatListOfSheetSetItems;
    },
    sheetSetItemsTableOfContents: function () {
      let sheetSetItemsTableOfContents = [];
      let index = 0;

      _forEach(this.sheetSetsWithParsedSheetPages, function (sheetSet) {
        let tableOfContentsBuffer = {
          sheetSetOrder: sheetSet.order,
          sheetSetItems: []
        };

        _forEach(sheetSet.sheetSetItems, function (sheetSetItem) {
          let sheetBuffer = {
            "sheetSetOrder": sheetSet.order,
            "id": sheetSetItem.id,
            "order": sheetSetItem.order,
            "sheet": sheetSetItem.sheet,
            "sheetId": sheetSetItem.sheetId,
            "sheetSetId": sheetSetItem.sheetSetId,
            "pages": []
          };

          _forEach(sheetSetItem.sheet.pages, function (page, pageIndex) {
            sheetBuffer.pages.push({
              "pageNumber": (pageIndex + 1),
              "tableOfContentsIndex": index
            });

            index++;
          });

          tableOfContentsBuffer.sheetSetItems.push(sheetBuffer);
        });

        sheetSetItemsTableOfContents.push(tableOfContentsBuffer);
      });

      return sheetSetItemsTableOfContents;
    },
  },
  methods: {
    setCarouselSlideIndex(newCarouselSlideIndex: number): void {
      this.slideIndex = newCarouselSlideIndex;
      // Use event because watching prop change in carousel component didn't seem to always be reliable.
      this.$root.$emit("change-carousel-slide-index", this.slideIndex);
      this.hideTableOfContentsModal();
    },
    showTableOfContentsModal: function () {
      this.$modal.show(this.$options.tableOfContentsModalName);
      this.tableOfContentsModalHidden = false;
    },
    hideTableOfContentsModal: function () {
      this.$modal.hide(this.$options.tableOfContentsModalName);
      this.tableOfContentsModalHidden = true;
    },
  }
}
