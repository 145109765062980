




import _cloneDeep from "lodash/cloneDeep";

import {Editor, EditorContent} from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  OrderedList,
  BulletList,
  ListItem,
  Bold,
  Italic,
  // Link,
  Strike,
  // Underline,
  // HorizontalRule,
  History
} from "tiptap-extensions";

import Note from "./body_editor/note";

import SheetUtils from "./sheet_utils";

// TODO: DRY with SheetEdit vue component
export default {
  props: {
    initialPageState: {
      type: String,
      required: true
    },
    initialTransposeAmount: {
      type: Number,
      required: true
    },
    useFlatNotes: {
      type: Boolean,
      required: true
    },
    scale: {
      type: Array,
      required: true
    }
  },
  components: {
    EditorContent
  },
  data: function (): object {
    let page = _cloneDeep(this.initialPageState) || "";

    return {
      page: page,
      editor: new Editor({
        editable: false,
        extensions: [
          new Blockquote(),
          new CodeBlock(),
          new Heading({levels: [1, 2, 3]}),
          new BulletList(),
          new OrderedList(),
          new ListItem(),
          new Bold(),
          new Italic(),
          // new Link(),
          new Strike(),
          // new Underline(),
          // new HorizontalRule(),
          new History(),

          new Note()
        ],
        onUpdate: ({getJSON, getHTML}) => {
          this.updateBodyData();
        }
      })
    };
  },
  computed: {},
  methods: {
    transposeNotesCallback: function (keyNoteValues) {
      this.transposeNotes(keyNoteValues.newKeyNote, keyNoteValues.intermediateKeyNote);
    },
    transposeNotes: function (newKeyNote, intermediateKeyNote) {
      this.transposeNotesFromTransposeAmount(newKeyNote - intermediateKeyNote);
    },
    transposeNotesFromTransposeAmount: function (transposeAmount) {
      // TODO: dedupe with sheet/edit.vue
      let editorContentJSON = this.editor.getJSON();

      const traverseEditorContent = (node) => {
        for (let key in node) {
          if (node[key] && typeof node[key] === "object") {
            traverseEditorContent(node[key])
          } else if (node.type === "note") {
            let nodeId = parseInt(node.attrs.noteId);
            let transposedNoteId = SheetUtils.correctTransposeScaleOverflow(nodeId + transposeAmount, this.scale.length);
            let transposedNoteName = SheetUtils.getNoteDisplayNameFromNoteIndex(transposedNoteId, this.scale, this.useFlatNotes);

            node.attrs.noteId = transposedNoteId;
            node.attrs.noteName = transposedNoteName;
          }
        }
      };

      traverseEditorContent(editorContentJSON);

      this.updateEditorContent(editorContentJSON);
    },
    updateEditorContent: function (content) {
      this.editor.clearContent();
      this.editor.setContent(content);
      this.updateBodyData();
    },
    updateBodyData: function () {
      this.page = this.editor.getHTML();
      // Add in line breaks so the saved markup is reflective of the editor markup
      this.page = this.page.split("<p></p>").join("<p><br></p>");
    },
  },
  created() {
    this.$root.$on("transpose-notes", this.transposeNotesCallback);
  },
  mounted() {
    this.editor.setContent(this.page);
    this.transposeNotesFromTransposeAmount(this.initialTransposeAmount);
  },
  beforeDestroy() {
    this.editor.destroy();
    this.$root.$off("transpose-notes", this.transposeNotesCallback);
  }
};
