

































import axios from "axios";

import _map from "lodash/map";

export default {
  props: {
    endpoint: {
      type: String,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    },
    userOptions: {
      type: Array,
      required: true
    }
  },
  data: function (): object {
    return {
      selectedUser: null
    };
  },
  computed: {
    userSelectOptions: function (): Array<Object> {

      return _map(this.userOptions, (userOption) => {
        return {
          id: userOption[0],
          username: userOption[1]
        }
      });
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
  },
  methods: {
    onSubmit: async function (event: object) {
      let isValid: boolean = await this.$validator.validateAll();

      if (!isValid) {
        return;
      }

      try {
        const response = await axios["post"](
          this.endpoint,
          {
            chat: {
              user_id: this.selectedUser.id
            }
          }
        );

        if (!response.data && !response.data.id) {
          return;
        }

        let urlSlug = response.data.slug || response.data.id;
        // @ts-ignore
        Turbolinks.visit(`${this.endpointBase}/${urlSlug}`);
      } catch (error) {
        this.$root.handleRequestError("saving", this.modelDisplayName, error);
      }
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this, true);
    }
  }
}
