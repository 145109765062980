


































































import _cloneDeep from "lodash/cloneDeep";
import _forEach from "lodash/forEach";

import CarouselItem from "../sheets/carousel_item.vue";

import CarouselUtils from "../carousel_utils";
import SheetUtils from "../sheets/sheet_utils";

export default {
  fadeInClass: "fade-in",
  fadeOutClass: "fade-out",
  // This should be the same as the css, plus a little extra
  fadeDurationInMs: 500,
  props: {
    slideIndex: {
      type: Number,
      default: 0
    },
    initialItemsState: {
      type: Array,
      required: true
    },
    scale: {
      type: Array,
      required: true
    }
  },
  components: {
    CarouselItem
  },
  data: function (): object {
    let items = _cloneDeep(this.initialItemsState) || [];

    return {
      activeSlideIndex: this.slideIndex,
      items: items,
      transitionClass: this.$options.fadeInClass,
      initialTouchData: CarouselUtils.getFreshTouchDataObject(),
      interstitialXTransformAmount: 0
    }
  },
  computed: {
    numberOfItems: function (): Number {
      let numberOfItems = 0;

      if (this.items && this.items.length) {
        numberOfItems = this.items.length;
      }

      return numberOfItems;
    },
    canGoToPreviousSlide: function (): boolean {
      return CarouselUtils.checkIfCanGoToPreviousSlide(this.activeSlideIndex);
    },
    canGoToNextSlide: function (): boolean {
      return CarouselUtils.checkIfCanGoToNextSlide(this.activeSlideIndex, this.numberOfItems);
    },
    activeItem: function () {
      let activeItem = null;

      if (this.items[this.activeSlideIndex]) {
        activeItem = this.items[this.activeSlideIndex];
      }

      return activeItem;
    },
    activeItemInitialState: function () {
      let activeItemInitialState = null;

      if (this.initialItemsState[this.activeSlideIndex]) {
        activeItemInitialState = this.initialItemsState[this.activeSlideIndex];
      }

      return activeItemInitialState;
    },
    transposeIndicator: function () {
      let transposeIndicator = null;

      if (this.activeItem.sheet.transposeAmount) {
        transposeIndicator = SheetUtils.calculateTransposeIndicator(this.activeItem.sheet.transposeAmount);
      }

      return transposeIndicator;
    },
    displayTitle: function (): string {
      let displayTitle = "";

      if (!this.activeItem) {
        return displayTitle;
      }

      if (this.activeItem.sheet.title) {
        displayTitle = this.activeItem.sheet.title;
      }

      if (this.activeItem.order) {
        displayTitle = `${this.activeItem.order} ${displayTitle}`;
      }

      if (this.activeItem.sheetSetOrder) {
        displayTitle = `${this.activeItem.sheetSetOrder}.${displayTitle}`;
      }

      if (this.activeItem.pageNumber) {
        displayTitle = `${displayTitle} (Page ${this.activeItem.pageNumber}`;

        if (this.activeItem.sheet.pages && this.activeItem.sheet.pages.length) {
          displayTitle += ` of ${this.activeItem.sheet.pages.length}`;
        }

        displayTitle += ")";
      }

      return displayTitle;
    },
    sheetKey: function (): string {
      // TODO: dedupe with sheet_perform.vue
      let sheetKey = "";

      if (!this.activeItem) {
        return sheetKey;
      }

      if (typeof this.activeItem.sheet.keyNote !== "undefined" && (this.activeItem.sheet.keyNote || this.activeItem.sheet.keyNote === 0)) {
        sheetKey = SheetUtils.getNoteDisplayNameFromNoteIndex(this.activeItem.sheet.keyNote, this.scale, this.activeItem.sheet.useFlatNotes);
      }

      if (this.activeItem.sheet.keyMode) {
        sheetKey += ` ${this.activeItem.sheet.keyMode}`;
      }

      return sheetKey;
    },
  },
  methods: {
    onTouchStart: function (event) {
      CarouselUtils.onTouchStart.call(this, event);
    },
    onTouchMove: function (event) {
      CarouselUtils.onTouchMove.call(this, event);
    },
    onTouchEnd: function (event) {
      CarouselUtils.touchFinishResetData.call(this, event);
    },
    onTouchCancel: function (event) {
      CarouselUtils.touchFinishResetData.call(this, event);
    },
    onSwipe: function (event) {
      CarouselUtils.onCarouselSwipe.call(this, event);
    },
    toPreviousSlide: function () {
      CarouselUtils.toPreviousSlide.call(this);
    },
    toNextSlide: function () {
      CarouselUtils.toNextSlide.call(this);
    },
    updateActiveSlideIndex: function (newSlideIndex) {
      CarouselUtils.updateActiveSlideIndex.call(this, newSlideIndex);
    },
    doTranspose: function (transposeAmount: number): void {
      // TODO: dedupe with sheet perform.
      let newKeyNote = SheetUtils.correctTransposeScaleOverflow(this.activeItem.sheet.keyNote + transposeAmount, this.scale.length);
      let intermediateKeyNote = this.activeItem.sheet.keyNote;
      let correctedTransposeAmount = SheetUtils.calculateNewTransposeAmount(this.activeItem.sheet.transposeAmount, transposeAmount);

      this.updateTransposeValues(newKeyNote, intermediateKeyNote, correctedTransposeAmount);
    },
    resetTranspose: function (): void {
      // TODO: dedupe with sheet perform.
      let newKeyNote = this.activeItemInitialState.sheet.keyNote;
      let intermediateKeyNote = this.activeItem.sheet.keyNote;
      let transposeAmount = 0;

      this.updateTransposeValues(newKeyNote, intermediateKeyNote, transposeAmount);
    },
    updateTransposeValues: function (newKeyNote: number, intermediateKeyNote: number, transposeAmount: number): void {
      // TODO: dedupe with sheet perform.
      _forEach(this.items, (item, key) => {
        if (item.id !== this.activeItem.id) {
          return;
        }

        this.items[key].sheet.intermediateKeyNote = intermediateKeyNote;
        this.items[key].sheet.keyNote = newKeyNote;
        this.items[key].sheet.transposeAmount = transposeAmount;
      });

      this.$root.$emit("transpose-notes", {newKeyNote: newKeyNote, intermediateKeyNote: intermediateKeyNote});
    }
  },
  created() {
    this.$root.$on("change-carousel-slide-index", (newSlideIndex) => {
      this.updateActiveSlideIndex(newSlideIndex);
    });
  }
}
