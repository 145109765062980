










































































































import _debounce from "lodash/debounce";

import StripeUtils from "../stripe_utils";

export default {
  penniesInADollar: 100,
  props: {
    accountName: {
      type: String,
      required: true
    },
    feesDescription: {
      type: String,
      required: true
    },
    minTipAmount: {
      type: Number,
      required: true
    },
    maxTipAmount: {
      type: Number,
      required: true
    },
    platformFeesByRegion: {
      type: Object,
      required: true
    },
    stripeFeesByRegion: {
      type: Object,
      required: true
    },
    confirmTipBaseUrl: {
      type: String,
      required: true
    },
    showCancelButton: {
      type: Boolean,
      default: false
    },
    demoMode: {
      type: Boolean,
      default: false
    }
  },
  data: function (): object {
    return {
      tipAmount: 100,
      includeFeesInTip: false
    };
  },
  computed: {
    feesAmount: function () {
      let feesAmount = 0;

      if (this.tipAmount) {
        feesAmount = this.calculateAmountWithFeesIncluded(this.tipAmount) - this.tipAmount;
      }

      return feesAmount;
    },
    formattedFeesAmount: function (): string {
      let feesAmount = this.feesAmount / this.$options.penniesInADollar;

      return feesAmount.toLocaleString("en-US", {style: "currency", currency: "USD"});
    },
    formattedMinTipAmount: function () {
      return (this.minTipAmount / this.$options.penniesInADollar).toFixed(2);
    },
    formattedMaxTipAmount: function () {
      return (this.maxTipAmount / this.$options.penniesInADollar).toFixed(2);
    },
    formattedTipAmount: function () {
      return (this.tipAmount / this.$options.penniesInADollar).toFixed(2);
    },
    amountWithFees: function () {
      return this.tipAmount + this.feesAmount;
    },
    calculatedAmount: function () {
      let calculatedAmount = this.tipAmount;

      if (this.includeFeesInTip) {
        calculatedAmount = this.amountWithFees;
      }

      return calculatedAmount;
    },
    formattedCalculatedAmount: function () {
      let calculatedAmount = this.calculatedAmount / this.$options.penniesInADollar;

      return calculatedAmount.toLocaleString("en-US", {style: "currency", currency: "USD"});
    },
    tipUserUrl: function (): string {
      let url = `${this.confirmTipBaseUrl}?amount=${this.calculatedAmount}`;

      if (this.includeFeesInTip) {
        url += `&fees=${this.feesAmount}`;
      }

      return url;
    },
  },
  methods: {
    calculateAmountWithFeesIncluded: function (amount) {
      return StripeUtils.calculateAmountWithFeesIncluded(amount, this.platformFeesByRegion, this.stripeFeesByRegion);
    },
    setTipAmount: function (amount) {
      this.tipAmount = amount;
    },
    resetTipAmount: function () {
      this.tipAmount = 0;
    },
    onTipAmountInput: _debounce(function (event) {
      let amount = event.target.value;

      if (
        !amount ||
        isNaN(amount)
      ) {
        return;
      }

      amount = parseFloat(amount).toFixed(2);
      amount *= this.$options.penniesInADollar

      this.tipAmount = amount;
    }, 400),
    emitCloseForm: function () {
      this.$emit("close-form");
    }
  },
}
