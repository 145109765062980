




















import TipFormBody from "./tip_form_body.vue";

export default {
  components: {
    TipFormBody
  },
  props: {
    aboutPaymentsProcessingPath: {
      type: String,
      required: true
    },
    accountName: {
      type: String,
      required: true
    },
    feesDescription: {
      type: String,
      required: true
    },
    minTipAmount: {
      type: Number,
      required: true
    },
    maxTipAmount: {
      type: Number,
      required: true
    },
    platformFeesByRegion: {
      type: Object,
      required: true
    },
    stripeFeesByRegion: {
      type: Object,
      required: true
    },
    confirmTipBaseUrl: {
      type: String,
      required: true
    },
  },
  data: function (): object {
    return {};
  },
  computed: {},
  methods: {},
}
