import { render, staticRenderFns } from "./teaser.vue?vue&type=template&id=2ae41b6c&scoped=true&"
import script from "./teaser.vue?vue&type=script&lang=ts&"
export * from "./teaser.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ae41b6c",
  null
  
)

export default component.exports