

























export default {
  props: {
    address: {
      type: String,
      required: true
    },
    title: String,
    body: String,
    url: String
  }
}
