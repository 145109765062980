




















































































import _cloneDeep from "lodash/cloneDeep";

export default {
  props: {
    canDelete: Boolean,
    endpoint: {
      type: String,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    initialGroupState: Object,
    modelDisplayName: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    let group = _cloneDeep(this.initialGroupState) || {
      title: "",
      body: ""
    };

    return {
      allowedToLeave: false,
      group: group,
      snapshotGroupState: {}
    };
  },
  computed: {
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotGroupState, this.group);
    },
    isEdit: function (): boolean {
      return this.$root.isEdit(this.group);
    },
    saveButtonText: function (): String {
      return this.$root.saveButtonText((this.errors.items.length > 0));
    },
    apiHttpMethod: function (): string {
      return this.$root.apiHttpMethod.call(this);
    }
  },
  methods: {
    editorOnUpdateCallback: function (updatedContent) {
      this.group.body = updatedContent;
    },
    onSubmit: async function (event: object) {
      await this.$root.onSubmit.call(this, event);
    },
    doSave: async function () {
      await this.$root.doSave.call(this, this.endpoint, this.group);
    },
    doCancel: async function () {
      await this.$root.doCancel.call(this);
    },
    doDelete: async function () {
      await this.$root.doDelete.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function () {
    this.snapshotGroupState = _cloneDeep(this.group);

    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy() {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
