import GetConsumerIfLoggedIn from "./consumer";

let consumer = GetConsumerIfLoggedIn();

if (consumer) {
  consumer.subscriptions.create({channel: "UserActivityChannel"}, {
    received(user_activity_data) {

    }
  });
}
