























































import _cloneDeep from "lodash/cloneDeep";
import _invert from "lodash/invert";

export default {
  props: {
    initialNotificationState: {
      type: Object,
      required: true
    },
    statusOptions: {
      type: Object,
      required: true
    },
    endpointBase: {
      type: String,
      required: true
    },
    endpoint: {
      type: String,
      required: true
    },
    modelDisplayName: {
      type: String,
      required: true
    }
  },
  data: function (): object {
    let notification = _cloneDeep(this.initialNotificationState) || {
      status: "unread"
    };

    let notificationIsRead = notification.status === "read";

    return {
      notification: notification,
      notificationIsRead: notificationIsRead,
      snapshotNotificationState: {},
    };
  },
  computed: {
    statusOptionsByValue: function (): object {
      return _invert(this.statusOptions);
    },
    checkboxId: function (): string {
      //  html checkboxes are weird, and since this component is reused on the
      //  the index page, the ID needs to be scoped so that the individual checkboxes
      //  from each component don't mess with each other (treated as the same)
      return `notification-${this.notification.id}-edit__status`;
    },
    updatedDate: function (): string {
      return this.$parent.formatDate(this.notification.updatedAt);
    },
    modelStateHasChanged: function (): boolean {
      return this.$root.modelStateHasChanged(this.snapshotNotificationState, this.notification);
    },
    apiHttpMethod: function (): string {
      return "put";
    }
  },
  // TODO: Move from watcher to computed property (and just use computed property in save action)
  watch: {
    "notificationIsRead": function (newValue, oldValue) {
      this.notification.status = this.$root.convertBooleanToEnum(newValue, this.statusOptionsByValue);
    }
  },
  methods: {
    doSave: async function () {
      let notificationData = _cloneDeep(this.notification);

      await this.$root.doSave.call(this, this.endpoint, notificationData, this.endpointBase);
    },
    doDelete: async function () {
      await this.$root.doDelete.call(this);
    },
    turboLinksBeforeVisitCallBack: async function (event) {
      await this.$root.turboLinksBeforeVisitCallBack.call(this, event);
    }
  },
  mounted: function (): void {
    this.snapshotNotificationState = _cloneDeep(this.notification);
    document.addEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  },
  beforeDestroy(): void {
    document.removeEventListener("turbolinks:before-visit", this.turboLinksBeforeVisitCallBack);
  }
};
